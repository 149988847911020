@import './color.scss';

.netHR-container{
    width: 100%;
    height: 100vh;
    // background-image: url('../assets/images/bg.png');
    background-image: url('../assets/images/bg.svg'); 
    // background-image: url('../assets/images/login.gif');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position:bottom right;
    .logo{
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        padding-top:63px;
        padding-bottom: 35px;
        .ant-image{
            margin: auto;
            max-width: 159px;
         //   max-height: 80px;
        }
    }
    .helper{   
        display: flex;
        justify-content: center;
        a{
            color: $grey_color;
        }
    }
    .netHR-card{
        .ant-card-body{
            padding:0 !important;
        }
        .ant-card-bordered {
            border-color: $grey8_color;
        }
        position: relative;
        display: block;
        max-width: 528px;
        min-height: 639px;
        height: auto;
        margin: auto;
        box-shadow: 0px 3px 20px 4px $shadow_color3;
        border-radius: 2px;
        padding-left:69px;
        padding-right:69px;
        margin-bottom: 28px;
        //new
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        .card-bottom-center{
            position: absolute;
            bottom: 20px;
            width: 390px;
            text-align: center;
        }
        
        h1.reset-form-title{
                text-align: center;
                padding-top: 48px;
                font-size: 24px;
                font-weight: 700;
                color: $grey_color;
        }
        p.red-alert{
            font-size :15.5px;
            color: $red2_color;
            padding-top: 15px;
            padding-bottom: 10px;
        }
        p.reset-form-description{
            font-size: 15.5px;
            color: $grey_color;
            font-weight: 400;
            padding-bottom: 16px;
        }
        .password-strength{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
        .check-password{
            
            display: flex;
            flex-wrap: wrap;
            padding-right: 10px;
            p{
                padding-left: 10px;
            }
            svg{
                margin-top: 3px;
            }
        }
        .ant-form-item-required{
            color:$grey_color;
            font-weight: 600;
            font-size: 15.5px;
        }
        .password-input .ant-input{
            height: 30px;
            margin-bottom: 0px;
        }
        .password-input{
            margin-bottom: 5px;
        }
        .ant-input{
            border-color:  $grey2_color;
            height: 40px;
            margin-bottom: 5px;
            font-size: 16px;
            color: $grey_color;
        }
        .ant-input::placeholder {
            color: $grey5_color;
        }
        .ant-input:focus, .ant-input-focused {
            box-shadow: none;
            border-color: $blue_text_color;
        }
        .react-tel-input .form-control {
            width: 100%;
            height: 40px;
            margin-bottom: 5px;
            font-size: 16px;
            border-color:  $grey2_color;
            color: $grey_color;
            margin-bottom: 6px !important;
            border-radius: 2px;
        }
        .ant-row.ant-form-item {
            margin: 0;
        }
        .ant-row.ant-form-item.custom-form{
            padding-bottom: 10px;
        }
        .react-tel-input .flag-dropdown {
            margin-bottom: 6px !important;
        }
        .switch{
            padding: 0;
            margin-bottom: 10px !important;
        }
       
        .submit-btn{
            padding-top: 23px;
            display: flex;
        }
        .register-btn{
            a{
                color: $blue_text_color;
            }
            width: 100%;
            position: absolute;
            bottom: 0;
            text-align: center;
            margin-left: -69px;
            padding-bottom: 18px;
            span{
                padding-left: 20px;
            }
        }
        .password-config{
            display: flex;
            justify-content: space-between;
            padding-top: 5px;
            a.forgot-password{
                color: $blue_text_color;
                font-size: 14px;
            }
            .ant-checkbox-inner {
              border: 2px solid $grey2_color;
              border-radius: 1px;
              background: transparent;
            }
             .ant-checkbox-checked .ant-checkbox-inner {
              background-color: transparent;
              border: 2px solid $blue_text_color !important;
              border-radius: 1px;
            }
            .ant-checkbox:hover .ant-checkbox-inner{
              border-color: $blue_text_color !important;
            } 
            .ant-checkbox-input:focus{
              border-color: $blue_text_color !important;
            } 
            .ant-checkbox-inner::after {
              top: 47.7%;
              left: 28.9%;
              width: 3.9px;
              height: 7px;
              border-color: $blue_text_color !important;
              border-radius: 32%;
            }
            span{
              font-size: 14px;
              font-weight: 600;
              color: $grey_color;
            }
        }
        .login-with{
            display: flex;
            padding-top: 16px;
            justify-content: center;
            color: $grey5_color;
        }
        .login-cards{
            padding-bottom: 8vh;
            .login-with-card{
                display: flex;
                justify-content: center;
                text-align: center;
                box-shadow: 0px 3px 20px 4px $shadow_color3;
                border-radius: 2px;
                margin-top: 15px;
                .ant-card-body{
                    padding: 5px 0px 5px 0 !important;
                    display: flex;
                }
                .ant-card-bordered {
                    border-color: $grey8_color;
                }
                span{
                    display: flex;
                    padding-left: 10px;
                    align-self: center;
                    font-size: 16px;
                    color: $grey_color;
                    font-weight: 400;
                }
            }
        }
        .hidden{
            display:none;
        }
    }
    .netHR-success-card{
        .ant-card-body{
            padding:0 !important;
        }
        .ant-card-bordered {
            border-color: $grey8_color;
        }
        width: 100%;
        position: relative;
        display: block;
        max-width: 528px;
        min-height: 474px;
        height: auto;
        margin: auto;
        box-shadow: 0px 3px 20px 4px $shadow_color3;
        border-radius: 2px;
        padding-left:49px;
        padding-right:49px;
        margin-bottom: 28px;
        .success-image{
            padding-top: 87px;
            width: 100%;
            display: flex;
            justify-content: center;        
        }
        h1{
            padding-top: 10px;
            display: flex;
            text-align: center;
            justify-content: center;
            color: $grey_color;
            font-size: 24px;
            font-weight: 400;
        }
        .success-bar{
            padding-left: 61px;
            padding-right: 61px;
            padding-top: 73px;
            .ant-progress-success-bg, .ant-progress-bg{
                background-color: $green_color;
            }
            .ant-progress-bg{
                height: 16px !important;
            }
        }
    }
    .netHR-gsm-card{
        .ant-card-body{
            padding:0 !important;
        }
        .ant-card-bordered {
            border-color: $grey8_color;
        }
        width: 100%;
        position: relative;
        display: block;
        max-width: 528px;
        min-height: 599px;
        height: auto;
        margin: auto;
        box-shadow: 0px 3px 20px 4px $shadow_color3;
        border-radius: 2px;
        padding-left:49px;
        padding-right:49px;
        margin-bottom: 28px;
        .title{
            padding-top: 48px;
        }
        .description{
            padding-top: 73px;
            padding-bottom: 49px;
        }
        h1{
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            color: $grey_color;
        }
        h2{
            color: $grey_color;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
        }
      
        .gsm-code{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .time{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
            .time-out{
                height: 3px;
            }
            input{
                display: flex;
                padding-left:15px;
                background-color: $grey3_color;
                border-color: $grey3_color;
                color: $grey_color;
                max-width: 103px;
                min-height: 39px;
            }
            .ant-form-item-required{
                display: flex;
                color: $grey_color;
                justify-content: center;
                font-size: 18px;
                line-height: 25px;
            }
            .ant-form-item-control-input{
                display: flex;
                align-self: center;
            }
            .ant-input:hover {
                border-color: $blue_text_color;
            }
        }
        .submit-btn{
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
        }
    }
}
.singIn-card{
   // padding-top:40px !important;
    min-height: 200px !important;
}