@import './color.scss';

.approve-on-mail-container {
    font-family: $font-family;
    width: 100%;
    height: 100%;
    text-align: center;
    background: $grey3_color;
    .approve-on-mail-card-container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    .approve-on-mail-card {
        background: $white_color;
        width: 100%;
        max-width: 500px;
        padding: 60px 60px 60px 60px;
        margin:0 20px !important;
        border-radius: 4px;
        box-shadow: 0 2px 3px $shadow-color;
        display: inline-block;
        margin: 0 auto;
        .approve-on-mail-icon-container{
            margin:0 auto;
            img {
                width: 180px;
                object-fit: cover;
              }
            svg{
              color: $yellow-border-color;
              width: 160px;
              height: 160px;
            }
        }
        .warning{
          color: $yellow-border-color;   
        }
        .success{
          padding-top: 0 !important;
          color: $green4_color;   
        }
        h1 {
            font-weight: 900;
            font-size: 40px;
            margin-bottom: 10px;
          }
          p {
            color: $grey_color;
            font-size:24px;
          }
      }
    }

  }
    
  