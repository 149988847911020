@import './color.scss';

.setting-holiday-tab{
    //max-width: 741px;
    .filter-column{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 30px;
        padding-right: 10px;
        h1{
            font-size: 16px;
            font-weight: 700;
        }
    }
    .table-title{
        display: flex;
        width: 100%;
        padding-left: 39px;
        h1{
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }
    }

    .holiday-card-table{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .card-body{
            display: flex;
            width: 100%;
            border-radius: 2px;
            border: 1px solid $shadow_color3 !important;
           //box-shadow: 0px 0px 16px $shadow_color3;
            margin-bottom: 10px;
        }
        .card-body-new-holiday{
            display: flex;
            width: 100%;
            border-radius: 2px;
            border: 1px solid $shadow_color3 !important;
            border-bottom: 0px !important;
        }
        .ant-card-body{
            width: 100%;
            padding: 0;
            padding-top: 18px;
            padding-bottom: 10px;   
            padding-left: 43px;
            padding-right: 0px;
        }
        h1{
            color: $grey_color;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        } 

        .date{
            font-weight: 400 ;
        }
    }
    .column-padding{
        padding-left: 45px;
        padding-right: 0px;
    } 
    .column-padding-new-holiday{
        width: 100%;
        padding: 0;
        padding-top: 18px;
        padding-bottom: 10px;
        padding-left: 43px;
        padding-right: 0px;
        background: white;
        border: 1px solid $shadow_color3;
        border-bottom: 0px !important;
    } 
 }
.work-hours-drawer{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-wrap: wrap;
    padding-top: 18px;
    padding-bottom: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
    .add-global-close-icon{
        padding-right: 24px;
    }
    .add-global-draw-title{
        padding-left: 24px;
        padding-bottom:24px;
    }
    .ant-input {
        height: 40px;
        max-width: 389px;
        font-size: 16px;
        border-color: $grey2_color;
        color : $grey_color;
    }
    .ant-input:hover, .ant-input:focus {
      border-color:$blue_text_color;
      box-shadow: none;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
      border-color: $blue_text_color;
      box-shadow: none;
    }
  
    .ant-form-item .ant-mentions, .ant-form-item textarea.ant-input {
      height: 80px;
    }
    .ant-form label {
      font-size: 16px;
      font-weight: 600;
      color: $grey_color;
    }
    .custom-select{
        max-width: 360px;
        .ant-input-affix-wrapper{
          padding:0px 11px !important;
        }
        svg{
          path{
            color: $breadcrump_color;
          }
        }
        .ant-select-single:not(.ant-select-customize-input)   .ant-select-selector,  .ant-select-selector {
          width: 100%;
          min-height: 40px;
          font-size: 16px;
          color: $grey_color;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-top: 4px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-search{
          top: 5px;
        }
      }
    .setting-work-hours-container{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-color: $white_color;
        border: none;
        padding-top: 10px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 0;
        .ant-form{
            display: flex;
            flex-wrap: wrap;
        }
        .column-left{
            border-right: 2px solid $grey2_color;
    
        }
        .column-right{
            padding-left: 80px;
        }
        .work-hours-checkbox{
            width: 100%;
            display: block;
            .ant-checkbox{
                top: 0.02em !important;
              }
        }
        .setting-work-hours-title{
            display: flex;
            h1{
                font-size: 18px;
                font-weight: 700;
                color: $secondary-dark-font-color;
                line-height: 22px;  
                padding-bottom: 10px;
            }
            .setting-work-hours{
                padding-left: 121px;
                padding-right: 282px;
            }
            .setting-work-hours-day{
                padding-right: 89px;
            }
            .setting-work-hours-start{
               padding-right:106px;
            }
        }
        .setting-work-hours-item{
            display: flex;
            width: 100%;
            padding-bottom: 20px;
        }
        .setting-work-hours-right-icon{
            height: 100%;
            display: flex;
            align-self: center;
            margin-left: 15px;
            margin-right: 15px;
            svg{
                color: $secondary-dark-font-color;
            }    
        }
        .work-hours-form-button{
            display: flex;
            width: 100%;
            padding-left: 320px;
            padding-top: 20px;
            @media all and (max-width: 1209px) {
                 justify-content: center;
                 padding-left: 0px !important;
            }
        }
        .ant-switch-checked{
            background-color: $green3_color !important;
        }
        .ant-switch{
            min-width: 74px;
            height: 30px;
            line-height: 30px;
            margin-right: 48px;
            margin-top: 5px;
        }
        .ant-switch-handle {
            top: 2.5px;
            width: 24px;
            height: 24px;
        }
        .ant-switch-handle::before {
            border-radius: 30px;
        }
        .ant-switch-checked .ant-switch-handle {
            left: calc(100% - 18px - 10px);
        }
        .toggle-text{
            font-size: 16px;
            font-weight: 400;
        }
        .ant-picker{
            min-width: 130px;
            height: 40px;
        }
    } 
}
.setting-work-hours-dropdown{
    .ant-picker-panel{
        min-width: 130px !important;
    }
}
.work-hours-checkbox{
    width: 100%;
    .ant-checkbox{
        position: relative;
        top: 5px;
        margin-top: 4px !important;
    }
    .ant-checkbox + span{
        position: relative;
        top:-1px;
    }
    label{
        font-weight: 400 !important;
    }

}
 .job-list-container{
    padding: 0 !important;
    padding-bottom: 24px !important;
    .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child{
        border: 0 !important;
    }
 }
 .job-list-column{
    padding-left: 42px !important;
    padding-right: 42px !important;
    padding-bottom: 0 !important;
    padding-top: 10px !important;
 }
 .job-list-item{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.document-type-disable-card{
    .custom-table-card{
        background-color: $grey3_color;
        border-color: $grey2_color !important;
    }
}
 .job-list-card{
    padding-left: 22px !important;
    padding-right: 22px !important;
 }
 .workhours-container{
     .ant-card-body{
         padding-right: 0 !important;
     }
 }
 .workhours-list-column{
    padding-left: 42px !important;
    padding-right: 23px !important;
    padding-bottom: 0 !important;
    padding-top: 10px !important;
 }
 .workhours-list-card{
    padding-left: 22px !important;
    padding-right: 22px !important;
    .workhours-title{
        font-weight: 700;
        cursor: pointer;
    }
    .custom-table-card-icon{
        svg{
            color: $grey_color;
            width: 28px;
            height: 28px;
        }
    }
    .workhours-check-class{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        svg{
            width: 28px;
            height: 28px;
            color: $grey_color;
        }
    }
  
 }
.holiday-text-center{
    text-align: center;
}
.holiday-radio{
    border-color: $water-green-500 !important;
    color: $water-green-500 !important;
}
.ant-radio-button-wrapper-checked.holiday-radio{
    background: $water-green-500 !important;
    color: white !important;
}   