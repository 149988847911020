@import './color.scss';

.page-container-pad-left-0{
  padding-left: 0 !important;
  .breadcrump , .tab-page{
    padding-left: 24px !important;
  }
}
.page-title2{
  position: relative;
  top: 10px;
  padding-left: 24px;
  font-size: 24px;
  color: $secondary-dark-font-color; 
  font-weight: bold;
}
.tab-page{
  h1.page-title{
    position: relative;
    top: 10px;
    padding-right: 125px;
    font-size: 24px;
    color: $secondary-dark-font-color; 
    font-weight: bold;
  }
  .ant-tabs-tabpane {
    min-height: 100px;
  }

  .ant-tabs {
    line-height: 0;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color : $grey_color;
    svg{
      color: $blue_text_color;
    }
  }
  .ant-tabs-ink-bar {
    height: 5px;
    background: $blue_text_color !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color : $grey_color;
    text-shadow: none;
  }
  .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 19px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
      color: $blue_text_color !important;
  }
  .ant-tabs-tab{
    width: 100%;
      font-size: 16px;
      margin-right: 35px !important;
      padding-bottom: 18px;
    svg{
      color: $blue_text_color;
    }
  }
  .ant-tabs-tab:hover{
    color : $blue_text_color !important;
  }
}
@media only screen and (max-width: 991px) {
  .person-information-left-container{
    border-right: 0 !important;
    
  }
}
.person-information-left-container{
  border-right: 1px solid $grey2_color;
  width: 100%;
  height: 100%;

  .change-avatar{
    cursor: pointer;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    color: $blue_text_color;
    font-size: 16px;
    svg{
      margin-right: 5px;
    }
  }
  .ant-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-image-img{
    border-radius: 50%;
    max-width: 232px;
    width: 100%;
    height: auto;
    max-height: 232px;
    padding-right: 10px;
    padding-left: 10px;
  }
.user-details{
  display: block;
  text-align: center;

  
  h1{
    font-size: 30px;
    font-weight: 700;
    color: $grey_color;
  }
  h3{
    font-size: 18px;
    font-weight: 400;
    color: $grey_color;
    line-height: 20px;
  }
  h3.email{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  .change-password-btn{
    margin-top:20px;
  }
  
}
.user-process{
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  button{
    svg{
      position: relative;
      top: 2px;
      width: 17px;
      height: 17px;
    }
    span{
      padding-left: 5px;
    }
  }
}
  .social-icon{
   display: flex;
   width: 100%;
   padding-top: 10px;
   padding-left: 20px;
   justify-content: center;
   .social-item{
     margin-right: 5px;
   }
   svg{
       font-size: 25px;
   }
  }
}

.fixed-btn{
  position: absolute;
  width: 100%;
  bottom: 0;
  background: $blue_color !important;
  border-top:1px solid $grey3_color;

  padding-bottom: 30px;
  z-index: 5;
  .flex-class{
    right: 103px;
    position: relative;
  }
}

.user-scrollable-tab .ant-tabs-content-holder{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 470px);
}

.add-org-draw-scrollable-tab .ant-tabs-content-holder{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 320px);
}
.advance-scrollable, .create-processes-drawer {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 215px);
}
.travel-scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 215px);
}
.expense-add-scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 91px);
}
.drawer-scrollable-tab .ant-tabs-content-holder , .scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 392px);
}
.drawer-scrollable-tab-full-width .ant-tabs-content-holder , .scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 300px);
}
.drawer-scrollable-tab-inventory .ant-tabs-content-holder , .scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 320px);
}
.department-scrollable, .ownership-scrollable, .approval-processes-detail-scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 390px);
}
.detail-scrollable{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 120px);
}
.add-draw-scrollable-tab > .ant-tabs-content-holder, .scrollable-form {
  overflow-y: auto;
  overflow-x: hidden;
  //max-height: calc(100vh - 320px);
  max-height: calc(100vh - 260px);
}
.add-processes-draw-scrollable-tab > .ant-tabs-content-holder, .scrollable-form {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 280px);
  padding-right:10px;
}
.timeoff-draw-scrollable-tab > .ant-tabs-content-holder, .scrollable-form {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 182px);
}
.add-timeoff-types-draw-scrollable-tab > .ant-tabs-content-holder, .scrollable-form {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 318px);
  padding-right:10px;
}
.update-timeoff-types-draw-scrollable-tab .ant-tabs-content-holder {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 247px);
  padding-right:10px;
}
.user-info-scrollable-tab .ant-tabs-content-holder {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 295px);
}
.education-draw-scrollable-tab > .ant-tabs-content-holder {
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  padding-right: 4px;
  max-height: calc(100vh - 280px);
}
// When we need show save button we can use this code for profile page
// .user-info-scrollable-tab .ant-tabs-content-holder {
//   overflow-y: auto;
//   overflow-x: hidden;
//   max-height: calc(100vh - 400px);
// }
.user-info-full-scrollable-tab .ant-tabs-content-holder {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.p-x-25{
  padding-left:25px;
  padding-right:25px;
}

.fixed-calendar{
  top:60px !important;
  z-index:50 !important;
  .ant-picker-range-arrow{
    display: none;
  }
}

.time-off-request-summary-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
//  margin:15px 0;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus{
  left: -4px;
  z-index: 2;
  color: $primary-dark-font-color;
  font-size: 20px;
  line-height: 20px;
  height: 30px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus{
  right: 9px;
  z-index: 2;
  color: $primary-dark-font-color;
  font-size: 20px;
  line-height: 20px;
  height: 30px;
}

.slick-list {
  padding-left: 20px !important;
  width: calc(100% - 30px);
}