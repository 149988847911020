@import "./color.scss";

.loading {
  display: flex !important;
  width: 100% !important;
  height: 76vh !important;
  max-height: 100vh !important;
}
.border-none {
  .form-left-body {
    border: none !important;
  }
  .form-right-body {
    padding-left: 0 !important;
  }
}
.table-details {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 18px;
  padding-left: 24px;
  padding-right: 24px;
  .back-drawer {
    button.ant-btn.transparent-btn {
      display: flex;
      padding: 0;
    }
    .ant-btn > span {
      padding-top: 2.3px;
      padding-left: 8px;
      display: inline-block;
    }
    a {
      display: flex;
      font-size: 16px;
      color: $blue_text_color;
    }
    .back-drawer-title {
      color: $grey_color;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
    }
  }
  .arrow {
    svg {
      color: $grey9_color;
    }
  }
  .arrow-down {
    svg {
      color: $grey9_color;
    }
  }
  .collapse-hr {
    border-color: $grey5_color;
  }
  .close-icon {
    cursor: pointer;
    padding-bottom: 34px;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-bottom: 10px;
    svg {
      font-size: 25px;
      path {
        color: $grey_color;
      }
    }
  }
  .add-social-media {
    padding: 5px;
  }
  .table-info {
    padding-bottom: 35px;
    .ant-card-bordered {
      border: 1px solid $blue_text_color !important;
    }
    .ant-card-body {
      padding: 0px;
      padding-top: 2px !important;
      background-color: $nav_menu_active_color;
    }
    .content {
      padding-top: 7px;
      svg {
        margin-top: 2px;
        font-size: 25px;
        path {
          color: $blue_text_color;
        }
      }
      h1 {
        color: $blue_text_color;
        font-size: 18px;
      }
    }
  }
  .custom-icon-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 129px;
    width: 129px;
    border: 1px solid $blue_text_color;
    border-radius: 50%;
    margin-bottom: 20px;
    svg {
      text-align: center;
      width: 70px;
      height: 70px;
      color: $blue_text_color;
    }
  }
  .avatar {
    border-radius: 50%;
    .ant-image-img {
      width: 129;
      height: 129px;
    }
    .change-avatar {
      cursor: pointer;
      span {
        color: $blue_text_color;
        font-size: 15px;
      }
      svg {
        position: relative;
        top: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .user-details {
    padding-top: 9px;
    padding-left: 10px;
    h1 {
      color: $secondary-dark-font-color;
      font-size: 30px;
      line-height: 1;
      font-weight: 700;
      margin: 0;
      padding-bottom: 5px;
    }
    .info-alt {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &-item {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &-icon {
          display: flex;
          position: absolute;
          svg {
            color: $blue_text_color;
            width: 20px;
            height: 20px;
          }
        }
        &-text {
          padding-left: 20px;
          span {
            padding-left: 3px;
            color: $grey_color;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
          }
        }
      }
    }
    .social-icon {
      display: flex;
      padding-top: 10px;
      .social-item {
        margin-right: 5px;
      }
      svg {
        font-size: 20px;
      }
    }
  }
  .changepage {
    width: 100%;
    display: flex;
    padding-top: 45px;
    justify-content: flex-end;
    font-size: 18px;
    color: $blue_text_color;
    .line {
      position: absolute;
      top: 16px;
      width: 26px;
      border: 2px solid $blue_text_color;
      transform: rotate(90deg);
      color: $blue_text_color;
    }
    .procces {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: 700;
      button {
        border-radius: 2px;
      }
    }
  }
  h3.collapse-title {
    font-size: 16px;
    font-weight: 600;
  }
  .hr-grey {
    margin-top: 5px;
    margin-bottom: 25px;
    width: 100%;
    border-right: 4px solid $grey2_color;
  }
  .hr-bottom {
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    border-bottom: 4px solid $grey2_color;
  }
  .view-mode {
    .form-text {
      font-size: 16px;
      color: $grey_color;
      height: auto;
      padding-right: 10px;
    }
    .ant-form label {
      padding-right: 10px;
    }
    .email {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
    .form-control {
      background-color: transparent !important;
      border: none !important;
    }
    .react-tel-input .form-control {
      position: relative;
      height: auto !important;
      line-height: 0 !important;
    }
    .react-tel-input :disabled {
      background-color: transparent !important;
      padding-left: 25px !important;
      cursor: text !important;
    }
    .flag-dropdown {
      background-color: transparent !important;
      border: none !important;
    }
    .react-tel-input .selected-flag {
      padding: 0 !important;
      display: flex;
      align-items: center;
    }
    .social-item {
      display: flex;
      height: 100%;
      align-items: center;
      svg {
        width: 40px;
        height: 40px;
        color: $blue_text_color;
      }
    }
    .ant-form-item {
      margin-bottom: 8px;
    }
  }
  .form-left-body {
    width: 100%;
    height: 100%;
    padding-right: 0px;
  }
  .profile-col-1 {
    @media all and (min-width: 991px) {
      border-right: 3px solid $nav_border_color;
    }
  }
  .profile-col-2 {
    @media all and (min-width: 1200px) {
      border-right: 3px solid $nav_border_color;
    }
  }
  .profile-col-3 {
    @media all and (max-width: 991px) {
      border-right: 0 !important;
    }
    @media all and (min-width: 992px) and (max-width: 1199px) {
      border-right: 3px solid $nav_border_color;
    }
    @media all and (min-width: 1200px) and (max-width: 1599px) {
      border-right: 0 !important;
    }
    @media all and (min-width: 1600px) {
      border-right: 3px solid $nav_border_color;
    }
  }
  .profile-col-4 {
    @media all and (min-width: 1200px) and (max-width: 1600px) {
      border-right: 3px solid $nav_border_color;
    }
  }
  .form-right-body {
    word-wrap: 100%;
    padding-right: 0px;
  }
  .form-work-label {
    p {
      font-size: 16px;
      color: $grey_color;
    }
  }
  .form-right-drawer-body {
    padding-right: 0;
  }
  .custom-tab {
    height: fit-content;
    color: $grey_color !important;
    min-width: 100%;
    min-height: 500px;
  }
  .ant-tabs-tab {
    font-size: $primary-font-size;
    padding: 9px 4px;
  }
  .ant-tabs-ink-bar {
    height: 5px;
    background: $blue_text_color !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $blue_text_color;
    font-weight: 400;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 19px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: $blue_text_color !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    border-color: transparent;
  }
  .ant-tabs-tab:hover {
    color: $blue_text_color !important;
  }
  .ant-checkbox-group {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    width: 65%;
  }
  .ant-picker {
    width: 100%;
  }
  .react-tel-input .form-control {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-color: $grey2_color;
    border-radius: 2px;
    color: $grey_color;
  }
  .react-tel-input :disabled {
    background-color: $grey3_color !important;
    color: $grey_color;
  }
  .ant-input {
    height: 40px;
    font-size: 16px;
    border-color: $grey2_color;
    color: $grey_color;
  }
  .ant-input:hover,
  .ant-input:focus {
    border-color: $blue_text_color;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: $blue_text_color;
    box-shadow: none;
  }
  .ant-form-item .ant-mentions,
  .ant-form-item textarea.ant-input {
    height: 80px;
  }
  .ant-picker {
    padding: 4px 0px 4px;
    border: 1px solid $grey2_color;
    span.anticon.anticon-calendar {
      margin-left: 10px;
    }
  }
  .ant-picker-suffix {
    width: 40px;
    height: 40px;
    top: 6px;
    right: 0;
    padding-top: 12px;
  }
  .ant-picker-clear {
    margin-right: 10px;
  }
  .ant-picker-input > input {
    font-size: 16px;
    padding-left: 10px;
    color: $grey_color;
  }
  .ant-picker-input {
    height: 30px;
  }
  .custom-select {
    .ant-input-affix-wrapper {
      padding: 0px 11px !important;
    }
    svg {
      path {
        color: $breadcrump_color;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-selector {
      width: 100%;
      min-height: 40px;
      font-size: 16px;
      color: $grey_color;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-top: 4px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      top: 5px;
    }
  }
  .auto-complete-input {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-selector {
      width: 100%;
      min-height: 40px;
      font-size: 16px;
      color: $grey_color;
    }
    .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      margin-top: 4px !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      margin-top: 2.5px;
    }
  }
  .table-details-error-form-class {
    margin: 0;
    margin-bottom: 2px !important;
  }
  .table-details-error-text {
    position: relative;
    top: 1px;
    color: $red_color;
    svg {
      position: relative;
      top: 2px;
      width: 14px;
      height: 14px;
    }
  }
  .custom-input-number {
    .ant-input-number {
      width: 100%;
      min-height: 40px;
      font-size: 16px;
      padding-top: 4px;
      color: $grey_color;
    }
  }
  .ant-form label {
    font-size: 16px;
    font-weight: 600;
    color: $grey_color;
  }
  .salary-class-form {
    .ant-input {
      height: 32px;
    }
  }
  .fixed-btn {
    position: fixed;
    width: 878px;
    bottom: 0;
    background: $blue_color !important;
    margin-left: -24px;
    padding-bottom: 30px;
    z-index: 5;
  }
  .permission-body {
    padding-top: 16px;
    .permission-status {
      h1.title {
        padding-top: 14px;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        text-align: center;
      }
    }
    .permission-info {
      width: calc(100% - 16px);
      padding-bottom: 20px;
      &-card {
        border: none !important;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.14) !important;
        margin-bottom: 10px;
        .ant-card-body {
          padding: 0;
          padding-left: 31px !important;
          padding-right: 31px !important;
          padding-top: 5px !important;
          padding-bottom: 11px !important;
        }
        p {
          font-size: 12px;
          height: 4px;
        }
        .perm-info-card-desc {
          h1 {
            font-size: 16px;
            font-weight: 400;
            height: 3px;
          }
        }
      }
      .ant-list-item {
        border: 0;
        padding: 0;
      }
    }
  }
  .advance-class {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 5px;
    .advance-add {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 5px;
      margin-bottom: 4px;
    }
    .advance-card-class {
      cursor: pointer;
      display: block;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
      .title-class {
        display: flex;
        width: 100%;
        padding-bottom: 17px;
        padding-left: 30px;
        h1 {
          font-size: 16px;
          line-height: 19px;
          color: $grey_color;
          font-weight: 700;
        }
      }
      .advance-card {
        display: flex;
        width: 100%;
        border: none !important;
        border-radius: 2px;
        box-shadow: 0px 0px 16px $shadow_color3;
        margin-bottom: 10px;
        padding-left: 5px;
        .ant-card-body {
          width: 100%;
          padding: 9px;
          padding-left: 30px !important;
        }
        p {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: $grey_color;
        }
        .ant-image {
          display: flex;
          align-items: center;
          height: 100%;
          &-img {
            max-height: 15px;
          }
        }
      }
    }
  }
  .filter {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    margin-bottom: 16px;
    .ant-select {
      width: 180px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }
  }
  .more {
    display: flex;
    justify-content: flex-end;
    color: $blue_text_color;
    cursor: pointer;
  }
  .pagination-end {
    display: flex;
    justify-content: flex-end;
    padding-right: 17px;
  }
  .form-submit, .form-submit-space-between {
    display: flex;
    flex: 1 1;
    width: 100%;
    padding-top: 3px;
    z-index: 5;
    .btn-padding {
      padding-left: 24px;
      &-end {
        padding-right: 20px;
      }
    }
  }
  .form-submit {
    justify-content: flex-end;
    align-self: flex-end;
  }
  .form-submit-space-between {
    justify-content: space-between;
    align-self: space-between;
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-end {
    display: block;
    margin-top: 4vh;
    .hr-end {
      margin-top: -4vh;
      margin-bottom: 36px;
      width: 100%;
      border: 1px solid $white_color;
      box-shadow: 0px 4px 5px $shadow_color2;
    }
  }
  .group-membership {
    padding-top: 27px;
    &-search {
      max-width: 384px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      .ant-input-suffix {
        color: $grey5_color;
        padding: 0;
      }
      .ant-input {
        height: 38px;
      }
    }
  }
}
.debit-body {
  padding-left: 0px;
  padding-bottom: 15px;
  padding-top: 5px;
  .debit-status {
    width: 100%;
    padding-right: 29px;
    .title {
      padding-top: 14px;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }
  }
  .debit-info {
    width: 100%;
    padding-bottom: 20px;
    .ant-list-lg .ant-list-item {
      padding: 0 !important;
    }
    .debit-info-card {
      border-radius: 2px;
      border: 1px solid $shadow_color3;
      margin-bottom: 10px;
      height: 56px;
      p {
        font-size: 12px;
        height: 4px;
      }
      &-desc {
        h1 {
          font-size: 16px;
          font-weight: 400;
          height: 15px;
        }
        .center {
          display: flex;
          height: 47px;
          align-items: center;
        }
      }
      .ant-card-body {
        padding: 0;
        padding-left: 31px !important;
        padding-right: 31px !important;
        padding-top: 5px !important;
        padding-bottom: 11px !important;
      }
    }
  }
}
.profile-info-alt {
  width: 100%;
  display: grid;
  justify-content: center;
  text-align: center;
  &-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    &-icon {
      position: relative;
      svg {
        color: $blue_text_color;
        width: 20px;
        height: 20px;
      }
    }
    &-text {
      padding-left: 5px;
    }
  }
}
.ownership-document-container{
  width: 100%;
  .document{
    &-upload-card-container{
      .ant-card-body{
        padding: 0 0 0 15px !important;
      }
    }
    &-action-buttons{
      min-height: 60px !important;
    }
  }
  .ant-upload-list-item-done{
    display: none !important;
  }
  .ant-upload-list-item{
    margin-bottom: 16px !important;
  }
  .ant-upload-list .ant-upload-list-picture{
      width: 100%;
  }
  .ant-upload.ant-upload-select {
      display: block;
  }
  // padding eklenicek
}
// Collapse yapisi ileride lazim olabilir
// .ant-collapse {
//   border: 0 !important;
//   color: $grey_color;
//   font-size: 16px;
//   border-radius: 2px;
//   background-color: $white_color;
//   margin-bottom: 10px;
//   border-bottom-width: 0;
// }
// .ant-collapse > .ant-collapse-item {
//   box-shadow: 0px 0px 16px $shadow_color3;
//   margin-bottom: 10px;
//   border-bottom: 0;
// }
// .ant-collapse-header{
//   padding: 0;
//   padding-top: 16px;
//   padding-left: 33px;
//   padding-bottom: 1px;
//   color: $grey_color;
// }
// .ant-collapse-content{
//   border: 0;
//   color: $grey_color;
//   padding-left: 13px;
// }
