@import './color.scss';

.download-pdf{
    display: flex;
    cursor: pointer;
    svg{
        width: 20px;
        height: 20px;
    }
}

.primary-green-font-color{
    color : $primary-green-font-color;
}
.subscription-container{
    display:flex;
    flex-direction: column;
    gap:20px;
    min-height: 170px;
}
.subscription-card-title{
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: $grey_color;
    margin:0px !important;
    text-align:start;
    &.md{
        font-size:18px;
        font-weight:400;
        color:$secondary-dark-font-color;
    }
    &.subtitle{
        font-size:16px;
        font-weight:400;
    }
    &.sm{
        font-size:14px;
        font-weight:400;
        color: $breadcrump_color;
    }
    &.sm-soft{
        font-size:14px;
        font-weight:400;
        color: $grey16_color;
    }
}

.subscription-package-name{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $primary-green-font-color;
    margin:0px !important;
    &.grey{
        color : $grey_color;
    }
}



.subscription-package-information{
    display:flex;
    flex-direction:column;
    gap:12px;
}
.subscription-button{
    display:flex !important;
    gap: 8px;
    align-items: center;
    min-height:44px;
    width:min-content;
    a{
        color: $primary-green-font-color !important;
    }
}
.subscription-payment-information-container{
    display:flex;
    gap:20px;
    flex-direction: column;;
}

.subscription-payment-gap{
    gap:6px;
}
.subscription-hr{
    width:100%;
    height:1px;
    background:$grey2_color;
}


/* Subscription Modals */
//styleName: H5/SemiBold;

.subscription-modal-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: $grey_color;
    margin:0px !important;
}

.subscription-modal-description{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: $secondary-dark-font-color;
    span{
        font-weight: 700;
        color: $grey_color;
    }
}

.subscription-button-link{
    width:max-content;
}

.subscription-credit-card-container{
    min-width: 164px;
    display:flex;
    align-items: center;
}
.subscription-card-error-alert {
    border-radius: 4px !important;
    span {
        align-self: start;
        margin-top: 8px;
    }
    .ant-alert-content {
        .ant-alert-message {
            color: $red-color; 
        }
    }
}