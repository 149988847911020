@import './color.scss';

.timeoff-modal,.delete-confirm-modal{
    text-align: center;
    .dynamic-modal-icon{
        color: $blue_text_color
    }
    .title{
        font-size:16px;
        font-weight: 600;
        margin-bottom:20px;
    }
    .ant-modal-footer{
        padding-left:24px !important;
        padding-right:24px !important;
    }
    .ant-modal-body{
        padding-bottom:0 !important;
    }
    .ant-modal-footer{
        border-top : 0px;
    }
}

.time-off-request-form{
    .ant-upload-list-item-done{
        display: none !important;
    }
    .ant-upload-list .ant-upload-list-picture{
        width: 100%;
    }
    .ant-upload.ant-upload-select {
        display: block;
    }
    .ant-picker-time-panel{
        .ant-picker-header-view{
            display: none;
        }
    }
    .ant-picker-footer{
        .ant-picker-ok{
            span{
                text-transform: capitalize;
            }
        }
    }
}
.timeoff-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 129px;
    width: 129px;
    padding:3px;
    border: 2px solid $blue_text_color;
    border-radius: 50%;
    svg{
        width: 60px;
        height: 60px;
        color: $blue_text_color;
        margin-left: 5px;

    }
}
.time-off-search-input{
    max-width: 384px;
    height: 40px;
    .ant-input{
        height: 30px;
    }
    .ant-input-suffix{
        svg{
            color: $grey5_color;
            width: 17px;
            height: 17px;
        }
    }
}
.timeOffType-container{
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 17px;
    .timeOffType-table{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 24px;
        .timeOffType-card{
            display: flex;
            width: 100%;
            border: none !important;
            border-radius: 2px;
            border: 1px solid $shadow_color3 !important;
           // box-shadow: 0px 0px 16px $shadow_color3;
            margin-bottom : 10px;
            .ant-card-body{
              width: 100%;
              padding: 9px;
              padding-left:24px !important;
            }
            h1{
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                color: $grey_color;
            }
            p{
              height: 100%;
              display: flex;
              align-items: center;
              font-size: 16px;
              color: $grey_color;
            }
            .switch{
                position: relative;
                top: 5px;
            }
          }
    }
}
.time-off-permission-container{
    display: block;
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding-top: 25px;
}
.remainder-action{
    position: relative;
    top: 0 !important;
    .remainder-export-button{
        position: absolute;
        right: 0;
        padding-left: 16px;
    }
    .remainder-decrease-button{
        padding-left: 16px;
    }
    .remainder-increase-button{
        padding-left: 16px;
    }
    @media all and (min-width:992px) and (max-width: 1058px) {
        .remainder-export-button{
            display:block;
            width: 100%;
            position: relative;
            padding-top: 10px;
            padding-left: 0 !important;
        }
    }
    @media all and (min-width:849px) and (max-width: 879px){
            .remainder-increase-button{
                padding-right: 20px;
            }
    }
    @media all and (max-width: 889px) {
        .remainder-export-button{
            position: relative;
            padding-top: 10px;
            padding-left: 0 !important;
        }
    }
    @media all and (min-width:600px) and (max-width:697px){
        .remainder-decrease-button{
            padding-right: 10px;
        }
    }
    @media all and (max-width:696px){
        .remainder-increase-button{
            padding-top: 10px;
            padding-left: 0 !important;
            padding-right: 16px;
        }   
    }
    @media all and (max-width:501px){
        .remainder-increase-button{
            width: 100%;
        }
        .remainder-decrease-button{
            width: 100%;
            padding-top: 10px;
            padding-left: 0 !important;
        }  
        .remainder-export-button{
            width: 100%;
        }       
    }
}
.timeoff-person-table-action-buttons{
    top: 20px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
@media all and (min-width: 624px) {
    .timeoff-table-left-action-buttons{
        width:400px !important ;
    }
}
.timeoff-table-left-action-buttons{
    display: flex;
    width: 100%;
}
.blue-tabs{
    .ant-tabs-tab{
        font-size: 14px !important;
    }
    .ant-tabs-nav-list{
        color: $blue_text_color;
        padding-bottom: 0px !important;
        border-bottom: 0px  !important;

    }
}
.custom-step-drawer-hide-tab{
    .ant-tabs-nav-wrap{
        display: none !important;
    }
}
.time-off-extra-value-modal{
    &-limit-width, .custom-select{
        .ant-input, .ant-select{
            // margin-left:26px;
            max-width: 206px !important;
        }
    }
    .ant-radio-group{
        margin-left: 26px;
    }
    .ant-radio-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}
.remaining-timeoff-card-wrapper{
    padding-right:16px;
}
.timeoff-rights-card{
    width: 100%;
    border: 1px solid $grey2_color !important;
    margin-bottom:15px !important;
    h1{
        font-size: 1rem;
        font-weight: bold;
        color:$secondary-dark-font-color;
    }
    .timeoff-person-drawer-card-container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 5px;
        .timeoff-person-drawer-card-item{
            display: flex;
            padding-bottom: 10px;
            .timeoff-person-drawer-card-label{
                font-weight:600;
                font-size:0.88rem;
                color:$secondary-dark-font-color;
            }
            .timeoff-person-drawer-card-text{
                line-height: 1.7em;
                padding-left: 5px;
                color:$secondary-dark-font-color;
            }
        }
    }
    .trc-label{
        font-weight:600;
        font-size:0.95rem;
        color:$secondary-dark-font-color;
    }
    .trc-row{
        display: flex;
    }
    .chart-column{
        width: 150px;
        display: inline-block;
        margin-right: 64px;
    }
    .info-column-primary{
        width: 300px;
        display: inline-block;
        margin-right: 64px;

    }
    .info-column-primary.sm,.chart-column.sm{
        margin-right: 48px;
    }
    .info-column-secondary{
        width: 300px;
        display: inline-block;
    }
    .info-column-secondary.sm{
        width: 280px;
    }
    .trc-column-row{
        display: flex;
        width: 100%;
        justify-content: space-between;
        line-height: 50px;
        div{
            display: inline-block;
        }
    }
}

.timeoff-container-rights-card{
    border: 1px solid $grey2_color;
    margin-bottom:15px;
    h1{
        font-size: 1rem;
        font-weight: bold;
        color:$secondary-dark-font-color;
    }
    .trc-label{
        font-weight:600;
        font-size:0.95rem;
        color:$secondary-dark-font-color;
    }
    .trc-row{
        display: flex;
        flex-wrap: wrap;
    }
    .chart-column{
        width: 150px;
        display: inline-block;
        margin-right: 64px;
    }
    .info-column-primary{
        width: 320px;
        display: inline-block;
        margin-right: 64px;

    }
    .info-column-primary.sm,.chart-column.sm{
        margin-right: 48px;
    }
    .info-column-secondary{
        width: 300px;
        display: inline-block;
        @media all and (min-width: 773px) and (max-width: 1242px) {
            width: 100%;
            max-width: 320px;
            margin-left: 215px;
        }
    }
    .info-column-secondary.sm{
        width: 280px;
    }
    .trc-column-row{
        display: flex;
        width: 100%;
        justify-content: space-between;
        line-height: 50px;
        div{
            display: inline-block;
        }
    }
}
.time-off-request-my-card{
    height: 100%;
    display: flex;
    margin-bottom: 10px !important;
}
.time-off-request-my-card-add-time-off{
    button{
        min-width: 180px;
    }
}
.time-off-choosen-day-button{
    width: 100%;
    display: flex;
    padding-top: 10px;
    span{
        font-size: 14px;
    }
    .ant-radio-button-wrapper-checked{
        color: $white_color !important;
        background-color: $blue_text_color !important;
        border-color: $blue_text_color !important;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
        background-color: $blue_text_color !important;
    }
    .time-off-choosen-start-time-picker{
        padding-left: 10px;
        .ant-picker-input{
            height: 23px;
            max-width: 117px;
        }
        .ant-picker-suffix {
            width: 33px !important;
            height: 40px !important;
            right: 0;
            padding-top: 13px !important;
            padding-left: 0px;
        }
    }
    .time-off-choosen-end-time-picker{
        padding-left: 0px;
        margin-left: 5px;
        .ant-picker-input{
            height: 23px;
            max-width: 110px;
        }
       .ant-picker-suffix {
            width: 33px !important;
            height: 40px !important;
            right: 0;
            padding-top: 13px !important;
            padding-left: 10px;
        }
        .ant-picker-clear{
            margin-right: 1px !important;
        }
    }
}
.time-off-choosen-start-time-picker-dropdown{
    li{
        font-weight: 400 !important;
    }
    .ant-picker-panel{
        min-width: 119px !important;
    }
}
.time-off-choosen-end-time-picker-dropdown{
    .ant-picker-panel{
        min-width: 118px !important;
    }
    li{
        font-weight: 400 !important;
    }
}
.time-off-choosen-day-type{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    height: 0;
}
.time-off-request-info-class{
    width: 100%;
    max-height: 150px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: scroll;
    border: 1px solid $grey2_color;
    border-radius: 5px;
    h3{
        width: 100%;
        color: $grey_color;
    }
}
.time-off-years-edit-modal{
    border: 1px solid $grey2_color;
    padding-bottom: 12px;
    .ant-form{
        display: flex;
        flex-wrap: wrap;
        width:100%;
        .ant-form-item{
            margin-right: 20px;
            min-width: 16.5em;
        }
        textarea.ant-input{
            min-width: 30em;
            min-height: 100px;
        }
        .ant-radio-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
        .ant-picker{
            height: 40px;
            padding: 0;
        }
        .ant-picker-input > input{
            font-size: 14px;
        }
        .ant-picker-range .ant-picker-active-bar{
            background: $blue_text_color;
        }
        .filter-details .ant-picker-suffix{
            padding-right: 10px;
        }
        .filter-details .ant-picker-range .ant-picker-clear {
            right: 2px;
        }
        .filter-details .ant-picker-input > input{
            min-width: 16.5em;
        }
    }
}