@import './color.scss';

.primary-btn{
    color:$white_color;
    background-color: $blue_text_color;
    font-weight: 700;
    border: 2px solid $blue_text_color;
    &:hover,:active,:focus, :active{
        color:$white_color !important;
        background-color: $blue_hover_color !important;
    }
    .ant-btn:active, .ant-btn:focus,  .ant-btn:hover{
        color:$white_color !important;
        background-color: $blue_hover_color !important;
    }
}
.grey-btn{
    color:$secondary-dark-font-color;
    background-color: $primary-light-background;
    font-weight: 700;
    border: 2px solid $grey15_color;
    &:focus,&:active, &:hover{
        color:$white_color !important;
        background-color: $grey13_color !important;
        border-color:  $grey13_color;
    }
}
.white-btn{
    color:$blue_text_color;
    border-color: $blue_text_color;
    background-color: transparent;
    background: transparent;
    font-weight: 700;
}
.white-btn:hover,.white-btn:focus,.white-btn:active{
    color:$blue_hover_color !important;
    border-color: $blue_hover_color !important;
    background: transparent;
}
.transparent-import{
    background: transparent !important;
}
.transparent-btn{
    color: $blue_text_color;
    background: transparent;
    border: none;
    box-shadow : none;
    .ant-btn{
        padding: 0;
    }
}
.transparent-btn:hover,.transparent-btn:focus, .transparent-btn::selection{
    color: $blue_text_color;
    background: transparent;
    border: none;

}
.transparent-btn,   [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
    --antd-wave-shadow-color: none !important;
}
.show_off{
    [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
        --antd-wave-shadow-color: none !important;
    }
  
}

.ant-btn.primary-btn:hover, .ant-btn.primary-btn:focus, .ant-btn.primary-btn:active {
    color:$white_color !important;
    border-color: $blue_hover_color !important;
    background-color: $blue_hover_color !important;
}