@import './color.scss';

.inventory-container{
    .ant-tabs-extra-content{
        @media all and (max-width: 1110px) {
          flex: none !important;
        }
      }
      @media all and (max-width: 550px) {
        .space-between{
            display: block !important;
            width: 100%;
        }
        .tab-btn{
            padding-top: 10px;
        }
        .white-btn{
            min-width: 100%;
        }
        .import-btn{
            margin-right: 0 !important;
          }
      }
      .tab-btn {
        @media all  and (max-width: 857px) {
          .primary-btn{
              display: none;
          }
          .white-btn{
              margin-top: 5px;
          }
          .export-btn{
            margin-right: 0 !important;
          }
        }
      }
}

.inventory-do-passive-text{
    font-size:16px;
    line-height: 16px;
    color: $primary-green-font-color;
    &:hover{
        color: $primary-green-font-color;
    }
}
.inventory-passive-class{
    display: flex;
    justify-content: center;
    h1{
        position: absolute;
        top: 40px;
        transform: rotate(-40deg);
        background-color: $white_color;
        padding-right: 40px;
        padding-left: 40px;
        color: $red_color;
        font-size: 30px;
        font-weight: 700;
    }
}
.inventory-slider-cart-container{
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;
    width: 100%;
    .collapse-text{
        display: flex;
        height: 100%;
        align-items: center;
        color: $grey_color;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }
    .inventory-slider-cart-row{
        width: 100%;
        padding-bottom: 17px;
        padding-left: 22px;
        h1{
            font-size: 16px;
            line-height: 20px;
            color: $grey_color;
            font-weight: 700;
        }
    }
    .ant-collapse{
        width: 100%;
        background-color: transparent;
        border-radius: 2px;
        border: 1px solid $shadow_color3;
        margin-bottom : 10px;
        svg{
            color: $grey9_color;
        }
    }
    .ant-collapse > .ant-collapse-item{
        border: 0;
    }
    .ant-collapse-content{
        border: 0;
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
        padding:0px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 7px !important;
    }
    .inventory-slider-cart-header{
        width: 100%;
        padding-left: 15px;
    }
    .ant-collapse-content > .ant-collapse-content-box{
        padding: 0 !important;
    }
    .inventory-slider-cart{
        &-body{
            padding: 21px;
        }
        &-description{
            margin: auto;
            font-size: 16px;
            color: $grey_color;
        }
        &-export{
            cursor: pointer;
            svg{
                color: $blue_text_color;
            }
        }
    }
    .ownership-not-found-text{
        font-weight: 400 !important;
    }
}
.show-other-inventory{
    padding-top: 10px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $blue_text_color;
    cursor: pointer;
}
.show-other-inventory::selection{
    color: $blue_text_color;
    background-color: transparent;
}
.import-button{
    width:110px;
}