@import './color.scss';

.travel-host-details-container{
    padding-top: 20px;
   
    .editable {
        padding: 20px;
        border: 1px solid $grey2_color;
        border-top: 0px;

        select {
          width:100px;
          background:transparent;
          appearance: none;
          background:$white_color;
          border: 1px solid $grey2_color;
          appearance: auto;
        }
        .ant-input{
            top: -1px;
            font-size: 14px;
        }
        .ant-picker{
            padding: 6px 0px 5px;
            top: -1px;
        }
        .ant-picker-input{
            height: 19px;
        }
        .ant-picker-input > input{
            font-size: 14px;
        }
     
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            font-size: 14px;
        }
        button.ant-btn {
            top: -1px;
        }
    }
    .travel-request-add-list{
        .country, .province, .modeOfTransportation,.datePicker  {
            width: 110px;
            max-width: 110px;
        }
        .hour{
            width: 110px;
            max-width: 110px;
            .ant-picker-clear{
                margin-right: 14px;
            }
        }
        .description{
            width: 130px;
            max-width: 130px;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
       
    }
    .host-request-details-list{
        .country, .province, .datePicker  {
            width: 160px;
            max-width: 160px;
        }
        .description{
            width: 170px;
            max-width: 170px;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
    }
}
.travel-host-container{
    padding-top: 20px;
   
    .editable {
        padding: 20px;
        border: 1px solid $grey2_color;
        border-top: 0px;

        select {
          width:100px;
          background:transparent;
          appearance: none;
          background:$white_color;
          border: 1px solid $grey2_color;
          appearance: auto;
        }
        .ant-input{
            top: -1px;
            font-size: 14px;
        }
        .ant-picker{
            padding: 6px 0px 5px;
            top: -1px;
        }
        .ant-picker-input{
            height: 19px;
        }
        .ant-picker-input > input{
            font-size: 14px;
        }
     
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            font-size: 14px;
        }
        button.ant-btn {
            top: -1px;
        }
    }
    .travel-request-add-list{
        .country, .province, .modeOfTransportation,.datePicker  {
            width: 120px;
            max-width: 120px;
        }
        .hour{
            width: 120px;
            max-width: 120px;
            .ant-picker-clear{
                margin-right: 14px;
            }
        }
        .description{
            width: 140px;
            max-width: 140px;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
       
    }
    .host-request-add-list{
        .country, .province, .datePicker  {
            width: 174px;
            max-width: 174px;
        }
        .description{
            width: 184px;
            max-width: 184px;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
    }
}