@import './color.scss';
.approval-processes-list{
    &-container{
        width: 100%;
        display: block;
    }
    &-title{
        color: $grey_color;
        font-size: 16px;
        font-weight: 700;
    }
    &-body{
        display: block;
        width: 100%;
        .processes-detail{
            background-color: $primary-light-background;
            padding: 0px 10px 0px 10px;
            line-height: 19.2px;
        }
        .more-icon{
            font-size: 32px;
            color: $blue_text_color;
        }
    }
}
.approval-custom-collapse{
    .ant-collapse, .ant-collapse-content-box, .ant-collapse-item,.ant-collapse-content{
        border: 0;
    }
    .ant-collapse-content, .ant-collapse{
        background-color: transparent;
    }
    .ant-collapse-header {
        border-bottom: 1px solid $grey5_color;
    }
    .collapse-icon{
        svg{
            color: $grey9_color;
        }
    }
    .ant-collapse-content-box{
        width: 100%;
        display: flex;
    }
}
.approval-custom-steps{
    .ant-steps-label-vertical .ant-steps-item-content{
        margin-top: 0;
    }
    .processes-icon{
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        svg{
            color: $grey_color;
           width: 36px;
           height: 36px;
        }
    }
    .ant-steps-item-tail{
        top: 15px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after{
        background-color: $blue_text_color;
    }
    .ant-steps-item-title{
        line-height: initial;
    }
    .result-step{
        .ant-steps-item-content{
            width: 140px !important;
        }
    }
}
.create-processes-step{
    display: block;
    &-item{
       display: flex;
       width: 100%;
       color: $blue_text_color;
       &-disabled{
       color: $grey5_color;
       }
       span{
        margin-top: 3px;
        padding-left: 2px;
       }
    }
    .ant-steps-item-content{
        min-height: 300px;
    }
}
.processes-form-checkbox-error{
    .ant-checkbox-inner, .ant-checkbox-checked::after, .ant-checkbox-wrapper:hover,.ant-checkbox-input:focus + .ant-checkbox-inner{
        border-color: $red_color !important;
    }
}
.processes-create-form , .processes-detail-form, .sortable-list-tab{
        display: block;
        width: 100%;
        padding: 0 !important;
        .ant-checkbox-input{
            padding: 0;
            margin: 0;
        }
        &-item{
            display: flex;
            flex-wrap: wrap;
        }
        label{
            width: 100%;
            color: $grey_color;
            font-weight: 600 !important;
        }
        .add-user-department-checkbox{
            &-all, &-list{
                label{
                    font-weight: 400 !important;
                }
            }
            &-list{
                padding-left: 10px !important;
                padding-top: 5px !important;
            }
        }
        .recursive-checkbox{
            .ant-form-item-control-input-content{
                flex:none;
            }
        }
    }
.processes{
    &-create-form{
        input, .approval-processes-select{
            margin:20px 0 20px 0;
        }
    }
    .sortable-list-tab, &-detail-form{
        input, .approval-processes-select{
            margin:12px 0 12px 0;
        }
        .ant-select, .ant-input{
            max-width: 360px;
        }
        
    }
}
.label-border-under{
    border-bottom: 1px solid $grey2_color;
}
.approval-processes-select{
    .ant-input-affix-wrapper{
      padding:0px 11px !important;
    }
    svg{
      path{
        color: $breadcrump_color;
      }
    }
    .ant-select-single:not(.ant-select-customize-input)   .ant-select-selector,  .ant-select-selector {
      width: 100%;
      min-height: 40px;
      font-size: 16px;
      color: $grey_color;

    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-top: 4px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search{
      top: 5px;
    }
  }
.approval-processes-select, .ant-select{
    width: 100%;
}
.transform-class{
    transform: scale(0.7);
}
.sortable-list{
    display: block;
    width:100%;
    &-tab{
        background-color: $white_color;
        visibility: visible;
        z-index: 99999999;
        cursor: grabbing !important;
        width: 100%;
    }
    &-detail-container{
        .ant-input, .ant-select{
            max-width: 360px;
        }
    }
    &-delete{
        position: relative;
        top: 6px;
        margin-left: 10px;
        cursor: pointer;
        svg{
            color: $blue_text_color;
        }
    }
    &-icon{
        display: flex;
        align-items: center;
        height: 100%;
        cursor:grab;
        svg{
            display: relative;
            top: 15px;
            color: $grey2_color;
        }
    }&-item{
        cursor: grabbing !important;
    }
    &-container{
        padding: 0;
        width: 100%;
        ul, li{
            padding: 0 !important;

        }
    }
}