@import './color.scss';

.advance-details-table{
     .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
        padding-left: 15px !important;
    }
    .blue-table .table-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
        padding-left: 15px !important;
        font-size: 15px !important;
    }
}
.advance-table{
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
        background-color: $blue_color;
        color: $grey_color !important;
        text-align: center;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
        font-size: 16px !important;
        color: $grey_color !important;
        text-align: center;
    }
}
.expense-drawer{
    .installment, .advancePay,.checkstatus{
        display: inline-flex;
        width: 200px;
        max-width: 200px;
    }
   
    .advance-details-table{
        padding: 7px 22px 7px 22px !important;
        .installment, .advancePay,.checkstatus{
            text-align: center;
        }
        .ant-checkbox-wrapper{
            margin-bottom: 0px !important;
        }
        
    }
   
}

