@import './color.scss';
.ant-select.ant-select-auto-complete.ant-select-single.ant-select-customize-input.ant-select-show-search{
  width: 473px !important;
}
.ant-select-dropdown.search-container.ant-select-dropdown-placement-bottomLeft.ant-select-dropdown{
  max-width: 473px !important;
}
.search-container{

  .ant-select-item{
    max-width: 100%;
  }
  ::selection{
    background-color: transparent !important;
  }
  .ant-select-item-option-selected{
    background-color: transparent !important;
  }
  .title{
    width: 100%;
    color: $grey_color;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid $grey7_color;
    padding-top: 5px;
    padding-bottom: 6px;
    margin-bottom: 10px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
  .ant-select-item-option-content{
    padding-bottom: 10px;
  }
  .search-bar{
      display: flex;
      a{
        display: flex;
        color: $grey_color;
      }
      span{
          display: flex;
          padding-left: 10px;
          align-self: center;
      }
      .icon{
          padding-top: 3px;
          padding-right: 10px;
      }
      .ant-image-img{
        border-radius: 55%;
      }
  }
    
}