@import "./color.scss";

.custom-table-card-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 5px;
  padding-right: 10px;
  .custom-table-card-add {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
  }
  .custom-table-card-class {
    display: block;
    width: 100%;
    padding-top: 20px;
    .custom-table-card-title-class {
      display: flex;
      width: 100%;
      padding-bottom: 10px;
      padding-left: 22px;
      padding-right: 19px;
      h1 {
        font-size: 16px;
        line-height: 19px;
        color: $grey_color;
        font-weight: 700;
      }
    }
    .custom-table-card {
      display: flex;
      width: 100%;
      border-radius: 2px;
      border: 1px solid $shadow_color3;
      margin-bottom: 10px;
      padding-left: 5px;
      .ant-card-body {
        width: 100%;
        padding: 9px;
        padding-left: 19px !important;
        padding-right: 19px;
      }
      p {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: $grey_color;
      }
      .custom-table-card-count {
        margin-left: 3px;
      }
      .ant-image {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .ant-image-img {
        max-height: 15px;
      }
    }
    .time-off-request-settings-list{
      span {
        font-size: 16px;
        line-height: 19px;
        color: $grey_color;
        font-weight: 700;
      }
      .ant-switch{
        min-width: 67px;
        height: 30px;
      }
      .ant-switch-handle {
        top: 4px;
        width: 22px;
        height: 22px;
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 24px - 2px);
      }
    }
  }
  .details-text {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    color: $secondary-dark-font-color;
    font-weight: bold;
  }
  a:hover.details-text {
    text-decoration: underline;
  }
  .details-empty-text{
    display: flex;
    height: 100%;
    align-items: center;
    color: $secondary-dark-font-color;
  }
}
.global-switch {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-right: 30px;
  padding-top: 10px;
  .toggle-text {
    width: 100%;
    display: flex;
    padding-right: 10px;
  }
}
.custom-table-card-multiple-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h1 {
    color: $grey_color;
    width: 100%;
    font-weight: 400 !important;
    line-height: 18px;
  }
}
.custom-table-list {
  .ant-list-item {
    padding: 0;
    border-bottom: 0;
  }
  li.ant-list-item.ant-list-item-no-flex {
    border-bottom: 0 !important;
    padding: 0;
  }
}
.custom-table-card-icon {
  display: flex;
  height: 100%;
  color: $blue_text_color;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
  }
}
.customize-salary-list{
  .custom-table-card-icon{
    svg{
      color: $grey_color;
      width: 28px;
      height: 28px;
    }
  }
}