@import './color.scss';

.task-drawer{
    .drawer-scrollable{
        overflow-y: auto;
        overflow-x: hidden;
    }
    .my-task-drawer{
        max-height: calc(100vh - 410px);
    }
    .assigned-task-drawer{
        max-height: calc(100vh - 408px);
    }
    .add-user-draw-footer{
        background-color: white !important;
    }
    .ant-form-label{
        height: 30px;
        margin-bottom: 0px;
    }
    .ant-form-label-item{
        position: relative;
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        height: 32px;
        font-size: 16px;
        color: $grey_color;
    }
    .task-btn{
        display: flex;
        text-align: center;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .next-btn{
        svg{
            margin-left:2px;
        }
    }
    .prev-btn{
        svg{
            margin-right:2px;
        }
    }
    .buttons-border{
        border: 1px solid $blue_text_color;
    }
    .step-achivement{
        display:flex;
        justify-content: flex-end;
        width: 100%;
        gap: 4px;
        &-item{
            text-align: center;
            .achivement-bar{
                display: block;
                width: 24px;
                height: 6px;
                border-radius: 8px;
                background-color: $grey2_color;
            }
            .active{
                background-color: $primary-green-background;
            }
            .step-achivement-icons{
                display: block;
                width: 100%;
            }
            .hide-icon{
                svg{
                    height: 0;
                }
            }
        }
    }
}
.task-step-search{
    border-right: 2px solid $grey14_color;
    padding: 0px 8px 16px  0px;
}
.task-step-list{
    padding-right: 24px;
    border-right: 2px solid $grey14_color;
    .anticon{
       font-size: 28px; 
    }
    .task-item-active{
        background-color: $shadow_color4;
    }
    .task-item-current{
        background-color: $yellow2_color;
        color: $white_color;
    }
    .ant-list-item{
        border-bottom: 0px !important;
        &-meta{
            padding: 0px 10px !important;
            align-items: center;
        }
        &-meta-content{
            display: flex;
            padding-left: 2px;
            height: 100%;
            label{
                font-size: 16px;
                color: $grey12_color;
            }
        }
        .active-text{
            color: $blue_text_color !important;
        }
    }
}