@import "./color.scss";

.dragger-table-card { 
  display: flex;
  width: 100%;
  border-radius: 2px;
  border: 1px solid $shadow_color3;
  margin-bottom: 12px;
  padding-left: 4px;
  .ant-card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
  }
  .ant-image {
    display: flex;
    align-items: center;
    height: 100%;
    &-img {
      max-height: 15px;
    }
  }
  p {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $grey_color;
  }
  &-icon {
    display: flex;
    height: 100%;
    color: $blue_text_color;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &-row {
    margin-bottom: 6px;
    background-color: $primary-light-background;
    .ant-card-bordered{
      border-color: $primary-light-background;
    }
    .ant-card-body{
      display:flex;
      padding: 0px 18px;
      background-color: $primary-light-background;
    }
    span {
      font-size: 16px;
      line-height: auto;
      color: $grey_color;
      font-weight: 700;
    }
  }
  &-count {
    margin-left: 3px;
  }
  &-class {
    display: block;
    width: 100%;
    padding-top: 20px;
  }
  &-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 5px;
    padding-right: 10px;
    &-add {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4px;
    }
    .details-text {
      display: flex;
      height: 100%;
      align-items: center;
      cursor: pointer;
      color: $secondary-dark-font-color;
      font-weight: bold;
      position: relative;
      top: 2px;
    }
    a:hover.details-text {
      text-decoration: underline;
    }
    .details-empty-text{
      display: flex;
      height: 100%;
      align-items: center;
      color: $secondary-dark-font-color;
    }
    .custom-table-list {
      .ant-list-item {
        padding: 0;
        border-bottom: 0;
      }
      li.ant-list-item.ant-list-item-no-flex {
        border-bottom: 0 !important;
        padding: 0;
      }
    }
    .sortable-list-detail-container{
      display: block;
      width: 100%;
      padding: 0 !important;
      cursor: grabbing !important;
      &-item{
        display: flex;
        flex-wrap: wrap;
        cursor: grabbing !important;
      }
      label{
        width: 100%;
        color: $grey_color;
        font-weight: 600 !important;
      }
      
    }
  }
  a{
    cursor: pointer;
    color: $secondary-dark-font-color !important;
    font-weight: bold;

  }
}