@import './color.scss';

.travel-container{
    padding-top: 20px;
    .ant-select-dropdown{
        max-height: 200px;
    }
    .travel-drawer-left{
        padding-right: 38px;
        border-right: 2px solid $grey2_color;

        .ant-picker-clear{
            margin-right: 15px;
        }
    }
    .travel-drawer-right{
        padding-left: 40px;
    }
    .travel-hours-dropdown{
        .ant-picker-panel{
            min-width: 135px !important;
        }
    }
    .editable {
        padding: 20px;
        border: 1px solid $grey2_color;
        border-top: 0px;

        select {
          width:100px;
          background:transparent;
          appearance: none;
          background:$white_color;
          border: 1px solid $grey2_color;
          appearance: auto;
        }
        .ant-input{
            height: 37.2px;
            top: -1px;
            font-size: 14px;
        }
        .ant-picker{
            padding: 6px 0px 5px;
            top: -1px;
        }
        .ant-picker-input{
            height: 19px;
        }
        .ant-picker-input > input{
            font-size: 14px;
        }
        .ant-picker-clear{
            margin-right: 4px;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            font-size: 14px;
        }
        button.ant-btn {
            top: -1px;
        }
    }
    .person-add-list{
        .name{
            width: 440px;
            max-width: 440px;
        }
        .companyName{
            width: 440px;
            max-width: 440px;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
       
    }
    .person-detail-list{
        .name{
            width: 350px;
            max-width: 350px;
        }
        .companyName{
            width: 350px;
            max-width: 350px;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
       
    }
   
}