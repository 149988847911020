@import './font.scss';
$dark-purple:rgba(94, 88, 115, 1);
$black-color: rgba(120, 120, 120, 1);
$black-color2:rgba(185, 185, 195, 1);
$aqua-color:rgba(57, 178, 172, 1);
$aqua-color2:rgba(62, 205, 198, 1);
$aqua-color3:rgba(62, 205, 198, 0.25);
$bg-gradient-color:linear-gradient(180deg, #D0DFEE 0%, rgba(248, 248, 248, 0) 100%);
$white_color: #FFFF;

.google-btn{
	display: flex;
	border: 1px solid #0a8d8724;
	padding: 22px;
	width: 30%;
	background-color: #f9fafb;
	align-items: center;
	justify-content: center;
	min-width: 120px;
    margin: auto;
    margin-top: auto;
    margin-top: 20px;
}

.google-btn:hover {
	border-color: #8bdfd8;
}

.google-span{
	color: #717171;
	font-size: 14px;
	font-weight: bolder;
	display: inline-grid !important;
	justify-items: flex-start;
	margin-left: 6px;
	line-height: 17px;
}

.auth-container {
    font-size: 16px;
    height: 100%;
    background-color: $white_color !important;
    a,input,label, ::placeholder, h2, h3, p {
        font-family: 'Montserrat', sans-serif;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
    .left-container{
        position: relative;
        width: 100%;
        height: 100%;
        background: $bg-gradient-color;
        .auth-logo{
            padding-left:94px;
            padding-top: 36px;
            padding-bottom: 36px;
        }
        .hr-app-container{
            position: flex;
            display: block;
            justify-content: center;
            text-align: center;
            align-items: center;
            height: 100%;
            width: 100%;
            .img{
                object-fit: cover;
                width: 100%;
                max-width: 492px;
            }
            .hr-app-description-container{
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                h2{
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 29.26px;
                    color: $dark-purple;
                }
                h3{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.5px;
                }
            }
            .hr-app-description-max-width{
                max-width: 560px;
            }
            .hr-staff-management-max-width{
                max-width: 470px;
            }
        }
        .app-container{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr 2fr 1fr;
            gap: 20px 40px;
        }
    }
    @media screen and (max-width:767px){
        .left-container{
            display: none !important;
        }
        .right-container{
            height: 100vh !important;
        }
        .ant-card{
            padding:0 24px 0 24px !important;
        }
        .auth-logo{
            display: block !important;
            width: 100%;
            justify-content: center;
            text-align: center;
            padding-bottom:16px;
        }
        .card-title{
            text-align: center;
            padding-bottom: 24px !important;
        }
    }
    .right-container{
        display:flex;
        flex-wrap:wrap;
        width: 100%;
        height: 100%;
        align-items: center;
        .auth-logo{
            display: none;
        }
        .ant-card{
            background-color: transparent;
            width: 100%;
            border: 0;
            .ant-card-body{
                padding: 0px 60px 0px 60px;
            }
            .card-title{
                padding-bottom: 30px;
                h2{
                    font-family: 'Montserrat', sans-serif;
                    color: $dark-purple;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 29.26px;
                }
            }
        }
    }
    .form-container{
        .form-label{
            font-size: 12px;
            color: $black-color;
        }
        ::placeholder { 
            font-size: 12px;
            line-height: 24px;
            color: $black-color2;
        }
        input[type=text], input[type=tel], input[type=email]{
            height: 38px;
            font-size: 12px;
            line-height: 24px;
            color: $black-color;
        }
        .form-check-label{
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
        }
        a.forgot-pass{
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
        .btn-aqua{
            display: flex;
            color: $white_color;
            text-align: center;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            height: 69px;
        }
        .btn-aqua,.btn-primary:not(:disabled):not(.disabled):active{
            background-color: $aqua-color;
            border-color: $aqua-color;
        }
         .btn-primary:not(:disabled):not(.disabled):active, .btn-aqua:hover{
            background-color: $aqua-color2 !important;
            border-color: $aqua-color2 !important;
        }
        .form-control:focus, .btn-primary:focus, .form-check-input:checked, .form-check-input:focus {
            border-color: $aqua-color2;
            box-shadow: 0 0 0 0.25rem $aqua-color3;
        }
        .form-check-input:checked{
            box-shadow: 0 0 0 0.25rem $aqua-color3 !important;
            background-color: $aqua-color2; 
        }
        .button-under-text-container{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            .button-under-text-max-width{
                display: flex;
                max-width: 360px;
                p{
                    font-size: 14px;
                    font-weight: 400;
                    color: $black-color;
                    line-height: 21px;
                }
                a{
                    color: $aqua-color;
                    &:hover{
                        color: $aqua-color2 !important;
                    }
                }
            }
        }
    }
}
.terms-container{
    display: block;
    padding:16px;
    h2{
        color: $black-color;
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 16px;
    }
    h3{
        color: $black-color2;
        font-size: 16px;
        line-height: 20px;
    }
    p{
        letter-spacing: 1;
        color: $black-color2;
        line-height: 20px;
    }
}