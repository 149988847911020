@import './color.scss';
@mixin play-icon {
    content: ""; 
    display: block;
    width: 70px; 
    height: 70px;
    background-image: url('../assets/svg/play-circle.svg'); 
    background-size: cover;
    color: $grey_color;
}
.education-container, .education-me-details-container{
    .green-tag, .yellow-tag{
        color: $white_color;
        border-radius: 5px;
    }
    .yellow-tag{
        background-color: $yellow_color;
        border-color: $yellow_color;
    }
    .green-tag{
        background-color: $green3_color;
        border-color: $green3_color;
    }
    .red-tag{
        background-color: rgb(190, 4, 4);
        border-color: red;
        color: #fff;
    }
}
.education-list{
    .custom-table-card-title-class{
        span{
            font-size: 16px;
            font-weight: 600;
            color: $grey_color;
        }
    }
    .custom-table-card{
        width: 100%;
        border-radius: 2px;
        border: 1px solid $shadow_color3;
        margin-bottom: 10px;
        padding-left: 5px;
        user-select: none;
        .ant-card-body{
            width: 100%;
            padding: 18px;
        }
    }
}
.education-container{
    .education-me-card{
        .ant-card-bordered{
            border: 1px solid $grey7_color;
        }
        .ant-card-cover{
            border:0 ;
            .ant-image{
                position: relative;
                width: 100%;
            }
            .ant-image::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 300px;
                top: 0;
                left: 0;
                background-color: rgb(0,0,0, .5);
            }
            &::before{
                @include play-icon;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -120%);
                cursor: pointer;
                z-index: 2;
                transition: transform 0.3s ease;
            }
            &:hover::before {
              transform: translate(-50%, -120%) scale(1.2);
            }
        }
        .ant-card-body{
            border-left: 0;
        }
    }

}
.education-me-details{
    &-container{
        .ant-tree{
            background: transparent !important;
            // &-switcher-leaf-line{
            //     display: none;
            // }
            &-switcher{
                background: transparent !important;
            }
             &-indent{
                 display: none;
             }
        }
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active{
            background-color: $grey7_color;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            color: $grey_color;
        }
        .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab{
            border-radius: 5px 5px 0 0;
            border-color: $black_color;
        }
        .ant-tabs-top > .ant-tabs-nav::before{
            border-bottom: 0px ;
        }
    }
    &-description{
        padding-top: 12px;
        h2, h4, p{
            color: $grey_color;
        }
        h2{
            font-size: 20px;
            font-weight: 600;
        }
        h4{
            font-size: 14px;
            font-weight: 600;
        }
        p{
            font-size: 16px;
        }
        .ant-tag{
            padding: 4px 12px 4px 12px;
            margin: 0 !important;
        }
    }
}

.education-edit-absolute{
    position:absolute;
    left:0;
    top:-20px;
    height: 38px;
    border-radius:4px;
    border:2px solid #E4E4E4;
    background:white;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index:999;
    cursor:pointer;

}
.eduction-edit-date-box{
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 8px;
    text-wrap: nowrap;
    font-size:14px;
    &.edit{
        border-left: 1px solid #E4E4E4;
        border-right: 1px solid #E4E4E4;
    }
    &.remove{
        color: $red-600
    }
    &:hover{
        background:#e4e4e44e
    }
}
.education-edit-date-pen{
    display:flex;
    align-items:center;
    justify-content: center;
    gap:3px;
}

    .education-edit-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > 
.ant-table-content > table > .ant-table-thead > tr > .ant-table-cell:nth-child(2) { 
    width:235px;
}

    .education-edit-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > 
.ant-table-content > table > .ant-table-tbody > tr > .ant-table-cell:nth-child(n+3):nth-child(-n+4) { 
    width:180px;
    height:43px;
}

.education-edit-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > 
.ant-table-content > table > .ant-table-tbody > tr > .ant-table-cell:nth-child(n+3):nth-child(-n+4) > .ant-picker  { 
    width:unset;
}

.education-edit-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > 
.ant-table-content > table > .ant-table-tbody > tr > .ant-table-cell:nth-child(n+3):nth-child(-n+4) > .ant-picker > .ant-picker-input { 
    height: 16px;
    width: auto;
}
.education-edit-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > 
.ant-table-content > table > .ant-table-tbody > tr > .ant-table-cell:nth-child(n+3):nth-child(-n+4) > .ant-picker > .ant-picker-input > input { 
    font-size:14px;
}

.education-bulk-date-pick-start > .ant-picker-input{
    height: 26px;
    width: 175px;
}
.education-bulk-date-pick-start > .ant-picker-input > input{
    font-size:14px !important;
}