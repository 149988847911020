@import './color.scss';

.custom-step-drawer{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 18px;
    padding-left: 24px;
    li{
        font-weight: 700;
    }
    .custom-step-drawer-header{
        display: flex;
        justify-content: flex-start !important;
    }
    .custom-step-drawer-title{
        display: flex;
        width: 100%;
        h1{
            font-size: 24px;
            font-weight: 700;
            color: $grey_color;
        }
    }
    .custom-step-drawer-back-text{
        display: flex;
        cursor: pointer;
        color: $blue_text_color;
        svg{
            font-size: 15px;
            position: relative;
        }
        span{
            font-size: 16px;
        }
    }
    .custom-step-drawer-close-icon{
        cursor: pointer;
        justify-content: flex-end;
    }
}

.custom-step-drawer-required-field-text{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: $grey_color;
    font-size: 16px;
    line-height: 19px;
    padding-top: 5px;
}
.custom-step-drawer-phone-input{
    margin-right: 10px;
}
.custom-step-drawer-phone-ext-input{
    margin-right: 3px;
}
.ant-form-item-required::before{
    display: none !important;
}
.custom-step-drawer-add-image{
    cursor: pointer;
    display: flex;
    span{
       font-size: 16px;
       color: $blue_text_color;
    }
    .ant-image-img{
        position: relative;
        top: 4px;
        max-width: 20px;
        max-height: 20px;
        margin-right: 5px;
    } 
}
.custom-step-drawer-footer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 113px;
    padding-left: 17px;
    padding-top: 36px;
    background-color: $blue_color;
    bottom: 0;    
}
.custom-step-drawer-footer-end{
    position: absolute;
    display: flex;
    padding-right: 16px;
    right: 0;
}
.hide-tab{
    padding-bottom: 50px;
    .ant-tabs-nav-wrap{
        display: none !important;

    }
}
.table-details .ant-form label{
    font-weight: 400;
}
.success{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    .ant-image-img{
        max-width: 50px;
        max-height: 50px;
    }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: none !important;
}
.hide-tabs{
 .ant-tabs > .ant-tabs-nav{
     display: none !important ;
 }
}