@import './color.scss';

.footer{
    width: 100%;
    background-color: $white_color;
  .ant-layout-footer{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $grey2_color;
    background-color: transparent;
    padding: 10px 24px;
    color: $secondary-dark-font-color;
  }
  .footer-wrap-text{
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      a, h1{
        font-size: $primary-font-size;
        padding-top: 14px;
        padding-right: 25px;
        color: $secondary-dark-font-color;

      }
  }
  .footer-center-text{
      margin:auto;
      h1{
          font-size: $primary-font-size;
          font-weight: 700;
          text-align: center;
          color: $secondary-dark-font-color;
          margin:0;
      }
      svg{
        position: relative;
        top: 2px;
      }
      h2{
          font-size: $primary-font-size;
          color: $secondary-dark-font-color;
          margin:0;

      }
  }
}