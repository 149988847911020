@import "./color.scss";
.trial-expired-content{
    padding: 70px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h4{
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
        margin: 20px 0;
    }
    img{
        margin-top:24px;
    }
}