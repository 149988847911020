@import './color.scss';

.is-changed{
    border:1px solid $primary-green-font-color !important;
    //max-width: 385px;
    border-radius:2px;
    &:focus {
        border-color: $primary-green-font-color;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow:$primary-green-box-shadow-color !important;
    }
}
.is-error{
    border:1px solid $red_color !important;
    //max-width: 385px;
    border-radius:2px;
    &:focus {
        border-color: $red_color;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow:$red_color !important;
    }
}
.person-table-responsive-dropdown{
    @media all and (max-width: 680px) {
      .ant-dropdown-menu{
        width: 100%;
      }
    }
}
.person-container{
    .ant-tabs-extra-content{
        @media all and (max-width: 1000px) {
          flex: none !important;
        }
      }
    .tab-btn {
        @media all  and (max-width: 857px) {
            .primary-btn{
                display: none;
            }
        }
        @media all and (min-width: 991px) and (max-width: 1034px) {
          display: grid;
          .primary-btn{
              margin-bottom: 10px;
              margin-right: 0 !important;
          }
        }
        @media all and (max-width: 854px) {
          display: grid;
          .primary-btn{
              margin-bottom: 10px;
              margin-right: 0 !important;
          }
        }
        @media all and (max-width: 680px) {
          display: block;
          padding-top: 25px;
          .w-178{
            width: 100%;
          }
          .primary-btn{
              width: 100%;
              margin-bottom: 10px;
              margin-right: 0 !important;
          }
        }
      }
}
.connectedPersonsDrawer{
    padding : 16px;
    padding-right: 10px!important;
}
.connectedPersonCard{
    border-radius: 2px;
    border: 1px solid $shadow_color3 !important;
    min-height: 263px;
    text-align: center;
    cursor: pointer;
    .ant-card-body{
        .name{
            color:$grey_color;
            font-weight: 600;
        }
    }
    .ant-image{
        width: 101px;
        height: 101px;
    }
}
.person-information-tabs{
    padding-right: 0 !important;
    .form-right-body{
        padding-right: 0 !important;
    }
    // .custom-table-card-container {
    //     padding-right: 0px !important;
    // }
}
.connectedPersonCardsWrapper{
    padding-top: 16px;
    padding-bottom: 16px;
    height: calc(100vh - 155px);
    margin-top:16px;
    margin-bottom:16px;
    padding-right: 16px;
    overflow-x: hidden;
}
.delete-person-container{
    padding-right: 15px !important;
    padding-left: 15px !important;
    .fixed-btn{
        margin-left: -15px !important;
    }
}
.delete-person-form-container{
    padding-bottom: 16px;
    height: calc(100vh - 200px);
    margin-bottom:16px;
    padding-right: 16px;
    overflow-x: hidden;
    .ant-select-single:not(.ant-select-customize-input).ant-select-selector,.ant-select-selector {
        width: 100%;
        min-height: 40px;
        font-size: 16px;
        color: $grey_color;
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 4px;
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-search{
        top: 5px;
      }
}
.delete-person-drawer{
    .ant-drawer-body{
        overflow: hidden !important;
    }
}
.connectedPersonsHeader{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.rounded-img{
    border-radius: 50%;
}
.delete-drawer-dismissall-class{
    padding-top: 10px;
}
.delete-drawer-information-class{
    padding-bottom: 20px;
    .delete-drawer-information-title{
        padding-bottom: 10px;
        h1{
            color: $grey_color;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }
    }
    .delete-drawer-information-item{
        .delete-drawer-information-description{
            padding-bottom: 15px !important;
        }
        .delete-draw-label{
            .ant-form-item-label label{
                font-weight: normal !important;
                padding-bottom: 0 !important;
            }
           ul{
               padding-left: 20px;
           }
        }
        label{
            display: flex;
            width: 100%;
            color: $grey_color;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            padding-bottom: 10px;
        }
    }
}
.loading-container{
    background-color: $white_color;
    border: 1px solid $grey2_color;
    min-height: 600px;
}

.select-width {
    .ant-form-item-control {
        min-width: 150px;
    }
}

.department-person-information{
    flex-direction:column;
}

