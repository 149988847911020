@import "./color.scss";

.Toastify__toast-container--top-right{
    top: 5em !important;
    right: 1em !important;
}
.Toastify__toast-container--bottom-right {
    bottom: 6em !important;
}
.Toastify__toast-container--bottom-right, .Toastify__toast-container--top-right{
    z-index: 99999999;
}
.custom-toast-message-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
    margin-top: 10px;
    h1{
        display: flex;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height:1.2rem;
        color: $secondary-dark-font-color;
    }
    span{
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: $secondary-dark-font-color;
        line-height:1.2rem;
        padding-bottom: 19px;
    }
    .ant-list{
        width: 100%;
    }
}