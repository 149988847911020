@import "./color.scss";
@import "./break-points.scss";
.ant-layout-content {
  height: 100%;
  overflow: auto;
  min-height: calc(100vh - 109px);
}
.ant-layout-header{
    position: fixed;
    display: block;
    align-content: center;
    width: 100%;
    height: auto;
    background-color: $white_color;
    font-family: $font_family;
    padding: 0;
    line-height: 0;
    padding-top: 6px;
    padding-bottom: 5px;
    border-bottom: 1px solid $nav_border_color;
    z-index: 100;
    max-height: 80px;

  &.header-with-bar{
    padding-top: 0;
    max-height: 146px;
  }

  @media all and (max-width:$custom-breakpoint-xl){
    &.header-with-bar{
      padding-top: 0;
      max-height: 186px;
    }
  }

  .app-icon {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding-top: 15px;

    svg {
      font-size: 30px;
    }
  }
  .logo {
    padding-left: 10px;

    img {
      width: 173px;
      height: auto;
      object-fit: cover;
    }
  }
  .search-input {
    margin-left: 17px;
    padding-top: 17px;

    /* .ant-input-search .ant-input-group .ant-input-affix-wrapper{
           // border-radius: 5px;
        }*/
    .ant-input-affix-wrapper {
      background-color: $grey3_color;
    }
    .ant-input-affix-wrapper > input.ant-input {
      background-color: $grey3_color;
    }
    ::placeholder {
      color: $grey5_color !important;
    }
    .ant-input-prefix {
      svg {
        color: $grey5_color;
      }
    }
    .ant-btn-icon-only {
      width: auto;
      height: auto;
      border: 0;
      padding: 0;
    }
    svg {
      color: $nav_border_color;
    }
    ::placeholder {
      color: $nav_border_color;
    }
    .ant-btn {
      box-shadow: none !important;
    }
    .ant-input-group-addon {
      background-color: transparent;
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      right: 4%;
      left: inherit;
      z-index: 3;
    }
  }

  .header-user-panel {
    align-items: center;
    gap: 24px;
    display: flex;
    .notification {
      padding-top: 15px;
      padding-right: 35px;
      svg {
        path {
          color: $grey_color;
        }
      }
    }
    .user-avatar {
      cursor: pointer;
      display: flex;
      overflow: hidden;
      padding:0 8px 0 0;
      img {
        flex: 1 1 auto;
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
      .avatar-details {
        flex: 0 0 auto;
        width: auto;
        text-align: right;
        margin: 8px 6px 0px 16px;
        .company {
          display: flex;
          flex-wrap: wrap;
          max-width: calc(100vw - 375px );
          line-height: 19px;
          color:$secondary-dark-font-color;
          font-size:12px;
          justify-content: flex-end;
        }
        .name{
          font-weight:bold;
          color:$secondary-dark-font-color;
          font-size:$secondary-font-size;
          line-height: 19px;
          justify-content: flex-end;
        }
      }
    }
    .change-organization-button {
      height: 100%;
      display: flex;
      color: $secondary-dark-font-color;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      gap:1px;
      padding: 0px;
      span{
        font-size: 10px;
        display: block;
      }
    }
  }
}
.change-organization-button, .switch-organization-list-item{
  cursor: pointer;
  .img-border{
    display: flex;
    align-items: center;
    border: 1px solid $grey2_color;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: contain;
    padding: 4px;
    .ant-image{
        margin:0 auto;
    }
  }
  &:hover{ 
    .img-border{
      border-color: $blue_text_color !important;
    }
  }
}
.switch-organization-list-item{
  span{
    text-wrap:wrap;
    text-align: start;
  }
  .ant-btn[disabled], .ant-btn[disabled]:hover{
    background-color: transparent;
    span{
      color: $grey_color !important;
      opacity: 0.6;
    }
    .img-border{
      border-color: $grey2_color !important;
    }
  }
  &-button{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    transform:all 0.5s ease-out;
    span{
      color: $grey_color;
      padding-left: 8px;
    }
    &:hover{ 
      span{
        color: $blue_text_color !important;
      }
    }
  }
}
.ant-dropdown-menu {
  padding: 0;
  min-width: 100px !important;
}

.company-menu {
  position: relative;
  right: 50px;
  width: 201px;
  height: auto;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 25px;
  padding-bottom: 25px !important;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: $grey_color;
    padding-bottom: 12px;
  }
  .company-item {
    padding-top: 23px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 13px;

    span {
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 14px;
      font-size: 16px;
      color: $grey_color;
    }
  }
}
.user-menu {
  background-color: $white_color;
  padding-top: 15px;
  .close-icon {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-right: 10px;
    svg {
      font-size: 25px;
      path {
        color: $grey_color;
      }
    }
  }
  .user-details {
    padding-left: 50px;
    padding-right: 50px;
    .avatar {
      display: flex;
      justify-content: center;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }
    }
    .user-desc {
      padding-top: 26px;
      justify-content: center;
      text-align: center;
      h1 {
        color: $secondary-dark-font-color;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
      }
      h2 {
        font-size: 12px;
        color: $grey_color;
        font-weight: 400;
      }
      a {
        font-size: 12px;
        color: $grey_color;
      }
    }
    .user-settings {
      padding-top: 25px;
      padding-bottom: 130px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a {
        width: 100%;
        svg {
          font-size: 15px;
        }
        font-size: 16px;
        color: $blue_text_color;
        padding-bottom: 17px;
      }
      .change-password-btn{
        color:$blue_text_color;
        font-size: 1rem;
        svg {
          width: 1rem;
          height: 1rem;
          margin-right:5px;
        }
      }
    }
  }
}
.hr {
  border-bottom: 1px solid $grey5_color;
}
.ant-layout-content {
  padding-top: 46px;
}
