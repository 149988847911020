@import "./color.scss";
@import "./break-points.scss";

.subscription-warning{
    background-color: $primary-green-background;
    height: 60px;
    padding:8px;
    color:white;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 20px;
    gap:8px;
}
.go-prices-btn{
    border:1.5px solid white;
    border-radius: 2px;
    padding:12px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:white;
    height: 44px;
    font-weight: 600;
    gap:4px;
    &:hover{
        color:white;
    }
}
.subsciption-d-none-mobile-block{
    display:block;
}
.subscription-payment-warning-text-block{
    display:flex;
    align-items:center;
    gap:8px;
    flex-direction: row;
}
@media all and (max-width:$custom-breakpoint-xl){
    .subscription-warning{
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        height: 106px;
        align-items: flex-start;
    }
    .go-prices-btn{
        height: 30px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }
    .subscription-payment-warning-text-block{
        display:flex;
        align-items:flex-start;
        gap:8px;
        flex-direction: column;
    }
    .subsciption-d-none-mobile-block{
        display:none;
    }
    
}

// payment warning
.subscription-payment-warning{
    background-color: $red-100;
    height: 60px;
    padding:8px;
    color:$red-600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 20px;
    gap:8px;
}

.go-warning-prices-btn{
    border:1.5px solid $red-600;
    border-radius: 2px;
    padding:12px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:$red-600;
    height: 44px;
    font-weight: 600;
    gap:4px;
    &:hover{
        color:$red-600;
    }
}

@media all and (max-width:$custom-breakpoint-xl){
    .subscription-payment-warning{
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        height: 106px;
        align-items: flex-start;
    }
    .go-warning-prices-btn{
        height: 30px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }
    
}



