@use "sass:math";
@import './color.scss';
$height : 225px;
$width : 350px;
.treeview-card-wrapper{
    padding-top:30px;
    background-color:none;
    margin-left:1px;
    height:$height;
    border-radius:2px;
    overflow:visible
}
.treeview-card-wrapper.department{
    height: $height - 20px;
    .treeview-card-container{
        height: $height - 52px;
    }
    .treeview-card-line{
        margin-top:0px;
    }
}
.treeview-card-container{
    height: $height - 32px;
    padding-top:0px;
    background-color:white;
    border:1px solid lightgray;
}
.treeview-card-img{
    margin-top:-30px;
    margin-left: math.div($width,2) - 30px;
    border-radius:100px;
    width:60px;
    height:60px;
}
.treeview-card-line{
    margin-top:-30px;
    background-color:$blue_text_color;
    height:10px;
    width:$width - 2px;
    border-radius:1px
}
.treeview-card-content{
    padding:20px; 
    padding-top:35px;
    text-align:center
}
.treeview-card-name{
    color:$grey_color;
    font-size:18px;
    font-weight:600;
}
.treeview-card-position{
    color:$grey_color;
    font-size:16px;
    margin-top:4px
}
.treeview-card-footer-wrapper{
    position: absolute;
    bottom: 10px;
    width: 100%;
}
.treeview-card-footer{
    display:flex;
    justify-content:space-between;
    padding-left:15px;
    padding-right:15px;
    font-size:16px;
}