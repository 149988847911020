@import './color.scss';
.ant-layout{
    height: 100%;
}

.forbidden-wrapper{
    text-align: center;
    img{
        width: 100%;
    }
    img.sm{
        width: 75%;
    }
}

.forbidden-header-text{
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: $secondary-dark-font-color;
    padding-bottom:15px;
    text-align: center;

}
.forbidden-header-helper-text{
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: $secondary-dark-font-color;
    padding-bottom:15px;


}

.forbidden-header-text.sm{
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom:20px;

}
.forbidden-header-helper-text.sm{
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding-bottom:20px;

}
.middle{
    padding-top:calc(50vh - 250px);

}
.middle.sm{
    padding-top:0;

}