@import './color.scss';


.department-container{
  .ant-tabs-extra-content{
    @media all and (max-width: 1110px) {
      flex: none !important;
    }
  }
  .tab-btn {
    @media all  and (max-width: 857px) {
      .primary-btn{
          display: none;
      }
    }
      @media all and (min-width: 991px) and (max-width: 1110px) {
        display: grid;
        .primary-btn{
            margin-bottom: 10px;
            margin-right: 0 !important;
        }
        .white-btn{
          width: 100%;
        }
      }
      @media all and (max-width: 893px) {
        display: grid;
        .primary-btn{
            margin-bottom: 10px;
            margin-right: 0 !important;
        }
        .white-btn{
          width: 100%;
          min-width: 178px;
        }
      }
      @media all and (max-width: 680px) {
        display: block;
        padding-top: 25px;
        .white-btn{
          min-width: 100%;
        }
        .primary-btn{
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0 !important;
        }
      }
  }
}
.department-logo{
    display: flex;
    text-align: center;
    align-items: center;
    height: 129px;
    width: 129px;
    padding:3px;
    border: 1px solid $blue_text_color;
    border-radius: 50%;
    margin-bottom: 20px;
  }

.import-button{
  width:110px;
}