@import './color.scss';

.changePasswordWrapper{
    padding:15px;
    .page-title{
        font-size:1.5rem;
        font-weight: bold;
        color:$secondary-dark-font-color;
        margin-bottom:15px;
    }
    .ant-input {
        height: 40px;
        font-size: 1rem;
        border-color: $grey2_color;
        color : $grey_color;
    }
    .ant-input:hover, .ant-input:focus {
        border-color:$blue_text_color;
        box-shadow: none;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
        border-color: $blue_text_color;
        box-shadow: none;
    }
    .text-danger{
        color:$red_color;
    }
    
    .ant-form{
        overflow-y: auto;
        max-height: calc(100vh - 163px);
        // padding-right:0;
    }
}

.password-check-list{
    padding:0;
}

.password-check-list li{
    display: inline-flex;
    padding-right: 15px;
    color:$secondary-light-font-color;
    align-items: center;
}

.password-check-list li.valid{
    span,svg.info-icon{
        color:$secondary-dark-font-color;
    }
    svg{
        color: $primary-green-font-color;
    }
    svg.info-icon{
        width: 1rem;
        height: 1rem;
        margin-left:5px;
    }
}

.password-check-list li.invalid{
    span,svg.info-icon{
        color:$secondary-light-font-color;
        opacity: 1;
    }
    svg{
        color: $red_color;
    }
    svg.info-icon{
        width: 1rem;
        height: 1rem;
        margin-left:5px;
    }
}
.pass-tooltip{
    display: flex;
    align-items: center;
    cursor:help;
}
.password-change-footer{
    position: absolute;
    bottom:0;
    padding:30px 15px;
    background-color: $blue_color;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
