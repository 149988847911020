@import './color.scss';
.ant-layout{
    height: 100%;
}

.successful-layout{
    background-color: #ffffff !important;
}

.successful-wrapper{
    padding-top:calc(50vh - 250px);
    text-align: center;
    .img-wrapper{
        width: 100%;
        height: 250px;
    }
    img{
        width: 250px;
        height: 250px;
    }
}

.successful-header-text{
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.75rem;
    color: $secondary-dark-font-color;
    padding-bottom:15px;
    text-align: center;
}
.successful-header-helper-text{
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.75rem;
    color: $secondary-dark-font-color;
    padding-bottom:15px;
}
