    @import './font.scss';
    
    // $font-family: "Calibri";
    // $primary-font-size: 16px;
    // $secondary-font-size: 14px;

    $primary-dark-background:#363740;
    $primary-light-background:#F7F8FC;
    $primary-light-font-color:$primary-light-background;
    $primary-green-background:#39B2AC;
    $secondary-green-background:#3ECDC6;
    $secondary-green-font-color:$secondary-green-background;
    $primary-green-font-color:$primary-green-background;
    $primary-dark-font-color:$primary-dark-background;
    $secondary-dark-font-color:#76777A;
    $secondary-light-font-color:#B6C8DB;
    $primary-green-box-shadow-color:0 0 0 2px rgba(57, 178, 172,0.2);


    $menu_hover_color: #95A1AE;
    $aqua_color: #84bad6;
    $black_color: rgb(0, 0, 0);
    $background_color: #fafafa;
    $breadcrump_color: #8C8C8C;
    $blue_color: #d7f0ee;
    $blue2_color: #2C78A5;
    $blue-border-color: $secondary-green-font-color;
    $blue_text_color: $primary-green-font-color; 
    $blue_hover_color: $secondary-green-font-color;
    $checkbox_th_color: rgba(89, 89, 89, 0.7);
    $grey_color: #595959;
    $grey2_color: #D9D9D9;
    $grey3_color: #F5F5F5;
    $grey4_color: #262626;
    $grey5_color: #bfbfbf;
    $grey6_color: #989797;
    $grey7_color: #D9D9D4;
    $grey8_color: #FBF7F7;
    $grey9_color: #535252;
    $grey10_color: #F2F2F2;
    $grey11_color: #F8F8F8;
    $grey12_color: #858585;
    $grey13_color: #ABABAB;
    $grey14_color: #F1F1F1;
    $grey15_color: #DFDFDF;
    $grey16_color: #BBBBBC;
    $grey17_color: #777C85;
    $green_color: $primary-green-font-color;
    $green2_color: $secondary-green-background;
    $green3_color: #389E0D;
    $green4_color: #51D786;
    $yellow-border-color: #fbb26b;
    $yellow_color: #FAA724;
    $yellow2_color: #FEE12B;
    $red_color: #F5222D;
    $red2_color: #E30917;
    $red3_color: #F15D5D;
    $red-border-color:#FA8072;
    $popup_border_color : #f0f0f0;
    $shadow-color: rgb(0 0 0 / 4%);
    $shadow_color2: rgba(0, 0, 0, 0.1);
    $shadow_color3: rgba(0, 0, 0, 0.14);
    $shadow_color4: rgba(217, 217, 217, 0.4);
    $shadow_color5: rgba(0, 0, 0, 0.6);
    $shadow_color6: rgba(64, 63, 63, 0.7); 
    $nav_menu_color: $primary-dark-background;
    $nav_menu_icon_color: $primary-green-font-color;
    $nav_menu_active_color: $primary-green-background;  
    $nav_border_color: $grey2_color;
    $helper_shadow_color: rgba(0, 0, 0, 0.25);
    $white_color: #fff;

    $blue-100: #EFF9F9;
    $blue-400: #90D3D8;
    $blue-500: #65C2C9;
    $blue-600: #519BA1;
    $text-50: #BBBBBC;

    $green-100: #ECF7EA;
    $green-200: #E2F2DF;
    $green-300 : #B5DEAE;
    $green-400 : #7AC36D;
    $green-500: #47AC34;

    $water-green-500: #39B2AC;

    $red-100: #FFECEA;
    $red-600: #CA342A;