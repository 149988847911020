@import './color.scss';

.contact-text{
   
    padding-top:10px;
    h1{
        color: $grey_color;
        font-weight: 600;
        font-size: 16px;
    }
    p{
        color: $grey_color;
        font-size: 16px;
         max-width: 439px;
         line-height: 25px;
    }
}
.contact-hr-class{
    display: flex;
    justify-content: flex-end;
    height: 528px;
    .contact-hr{
        border: 1px solid $grey5_color;
    }
}
