@import './color.scss';

.apexcharts-canvas {
    width: 100% !important;
    svg{
        width: 100% !important;
    }
}
.homepage-dropdown-menu{
    min-width: 220px !important;
    max-width: 100% !important;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
    padding-right: 23px;
}
.dashboard-action-button-class{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;
}
.dashboard-card-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 36px;
}
.dashboard-reports-pie-card{
    width: 100%;
    display: flex;
    box-shadow: 0px 4px 20px $shadow_color2;
    min-height: 272px;
    height: 100%;
    .ant-card-body{
        width: 100%;
        border-radius: 2px;
        padding-top: 23px;
        padding-left: 30px;
        padding-right: 21px;
        padding-bottom: 14px;
    }
    .dashboard-reports-pie-title{
       h1{
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $grey_color;
       }
    }
    .dashboard-reports-pie-container{
        display: flex;
        justify-content: flex-start;
        padding: 0;
        width: 100%;
        max-width: 400px;
    }
}
.dashboard-card{
    width: 100%;
    display: flex;
    box-shadow: 0px 4px 20px $shadow_color2;
    //height: 310px;
    min-height: 310px;
    height: 100%;
    .ant-card-body{
        width: 100%;
        border-radius: 2px;
        padding-top: 23px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 14px;
    }
    .dashboard-card-title{
        display: flex;
        h1{
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            color: $grey_color;
        }
        svg{
            color: $primary-green-font-color;
            margin-right: 10px;
            margin-top: 2px;
            width: 20px;
            height: 20px;
        }
    }
    .dashboard-card-reports-title{
        display: flex;
        h1{
            font-size: 24px;
            font-weight: 600;
            line-height: 25px;
            color: $grey_color;
        }
        svg{
            color: $primary-green-font-color;
            margin-right: 10px;
            margin-top: 2px;
            width: 20px;
            height: 20px;
        }
    }
    .dashboard-time-off-request-card-table{
        display: flex;
        width: 100%;
        padding-top: 17px;
        overflow-y: auto;
        max-height: calc(310px - 120px);
        padding-right: 4px;
        .ant-image-img{
            border-radius: 50%;
            height: auto;
            max-height: 50px;
        }
    }
    .dashboard-card-table{
        display: flex;
        width: 100%;
        padding-top: 17px;
        overflow-y: auto;
        max-height: calc(310px - 108px);
        padding-right: 4px;
        color: $grey_color;
        .ant-image-img{
            border-radius: 50%;
            height: auto;
            max-height: 50px;
        }
        &-title{
            display: flex;
            height:100%;
            max-height: 50px;
            width:100%;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: $grey_color;
        }
        &-text{
            display: flex;
            height:100%;
            max-height: 50px;
            width:100%;
            align-items: center;
            font-size: 16px;   
        }
        &-icon{
            display: flex;
            height:100%;
            max-height: 50px;
            width:100%;
            align-items: center;
            font-size: 16px;
            color: $primary-green-font-color;
            svg{
                width: 22px;
                height: 20px;
            }
        }
        &-custom-icon{
            margin-right: 5px;
            svg{
                width: 15px;
                height: 10px;
            }
        }
    }
    .dashboard-timeOff-card{
        display: flex;
        width: 100%;
        padding-bottom: 20px;
    }
    .dashboard-card-all-view{
        position: absolute;
        bottom: 10px;
        right: 30px;
        span{
            font-size: 16px;
            color: $primary-green-font-color; 
        }
        svg{
            position: relative;
            top: 7px;
        }
    }
    .dashboard-pai-chart-container{
        display: flex;
        justify-content: center;
        @media all and (max-width: 991px) {
            padding-bottom: 47px;
        }
        .dashboard-pai-chart{
            height: 184px;
            width: 184px;
        }
        .pai-chart-remain{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 6px;
            font-size: 16px;
            color: $grey_color;
            font-weight: 600;
            line-height: 22px;
        }
        .pai-chart-total{
            padding-top: 5px;
            font-size: 48px;
            font-weight: 400;
            color: $grey_color;
        }
    }
}
.dashboard-card-table-text{
    color: $grey_color;
    &:hover{
        color: $grey_color;
    }
}
.dashboard-employee-text{
    line-height:18px;
}
// .apexcharts-legend-series {
//     width: 150px;
//     word-break: break-word;
// }
// .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left{
   // bottom : 10px !important;
// }