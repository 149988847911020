@import './color.scss';
@import './break-points.scss';

.checkout-content{
    .steps-wrapper{
        padding-top:68px;
        background-color: $white_color;
        @media (max-width: $custom-breakpoint-sm) {
            padding-left: 8px;
            padding-right: 8px;
        }

        .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
            @media (max-width: $custom-breakpoint-sm) {
                padding-left: 8px;
            }
        }
        
        .ant-steps{
            align-items: center;
            min-height: 90px;
            max-width: 600px;
            margin: 0 auto;
        }
        .ant-steps-item-container {
            min-height: unset;
        }
    
        .ant-steps-item-title{
            color: $blue-500;
            @media (max-width: $custom-breakpoint-sm) {
                font-size: 12px;
                line-height: 27px;
                padding-right: 8px;
                &::after{
                    top: 12px;
                }
            }
    
        }
    
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after , .ant-steps-item-title::after {
            background-color:$text-50 !important;
        }
    
        .ant-steps-item-icon {
            border-color: $blue-500;
            @media (max-width: $custom-breakpoint-sm) {
                width: 24px;
                height: 24px;
                margin: 0 4px 0 0;
                font-size: 10px;
            }
            .ant-steps-icon {
                @media (max-width: $custom-breakpoint-sm) {
                    top: -5.5px;
                }
            }
        }
    
        .ant-steps-item-finish .ant-steps-item-icon, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
            background: $blue-500 !important;
            background-color: $blue-500;
            border-color: $blue-500;
            color:$white_color;
            svg{
                fill: $white_color;
            }
        }
    
        .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon
        {
            border-color: $blue-500;
            color: $blue-500;
            
        }
    
        .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
        .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon{
            color: $blue-500;
        }
    
        .ant-steps-item-wait .ant-steps-item-icon {
            border-color: $text-50;
            color:$text-50;
        }
    
        
    }
    .ant-layout-content{
        padding-top:0;
        min-height: calc(100vh - 227px);
    }
    .ant-divider{
        border-color: $green-300 !important;
        margin :8px 0 32px 0;
    }
    .payment-card-title{
        display: inline-block;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        color: $grey_color;
        margin-bottom: 16px;
        margin-right: 16px;
    }
    
    
}
.prices-wrapper{
    text-align: center;
    &>div{
        margin-top:16px !important;
    }
}
.period-switch{
    max-width: 200px;
    padding: 4px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    background: #4CAF50;
    margin:0 auto;
    min-height: 40px;
}
.price-card-wrapper{
    margin :0 auto !important;
    margin-top:16px;
    padding:0 12px;
    .table-empty-container{
        background-color: #fff;
        padding:16px;
    }
}
.price-card{
    min-width: 230px;
    // @media (min-width: $custom-breakpoint-lg) and (max-width : $custom-breakpoint-xl) {
    //     min-width: 230px;
    // }
    @media (min-width: $custom-breakpoint-xxl) {
        min-width: 332px;
    }
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

    .ant-card-body{
        text-align: left;
        padding: 16px 24px;
        @media (min-width: $custom-breakpoint-lg) and (max-width: $custom-breakpoint-xxl) {
            padding: 16px 12px;
        }
    }

    .worker-count{
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        @media (min-width: $custom-breakpoint-xxl) {
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
        }
        color: #595959;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 16px;
        svg{
            fill: $blue-500;
        }
    }
    .old-price{
        text-decoration: line-through;
        color: #595959;
        height: 20px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 200;
    }
    .tl-price{
        color: #595959;
        height: 20px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 200;
    }
    .price{
        font-size: 32px; line-height: 36px; font-weight: 600; color: #123937;
        @media (min-width: $custom-breakpoint-xxl) {
            font-size: 48px; line-height: 52px;
        }
    }
    .price-ext{
        font-size: 24px; line-height: 28px; font-weight: 400; color: #123937;
    }

    .ant-card-head{
        border-bottom:0;
        text-align: left;
        @media (min-width: $custom-breakpoint-lg) and (max-width: $custom-breakpoint-xxl) {
            padding: 0 12px;
        }
    }

    .ant-card-head-title {
        color:$water-green-500;
        font-weight: 700;
        font-size:24px;
        line-height: 28px;
        @media (min-width: $custom-breakpoint-lg) and (max-width: $custom-breakpoint-xxl) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .ant-card-extra{
        display: none;
        .ant-tag{
            @media (min-width: $custom-breakpoint-lg) and (max-width: $custom-breakpoint-xxl) {
                margin-right:0;
            }
        }
    }

    &:hover,&.active {
        cursor: pointer;
        outline: 2px solid $green-500;
        .ant-card-head{
            background-color: $green-200;
        }
        .ant-card-extra{
            display:block;
        }
    }

    .ant-tag-green{
        background-color: $white_color;
        border:0;
    }

    
}
.price-card-sub-wrapper{
    position:relative;

}
.selected-price-icon{
    background:$white_color;
    position:absolute;
    top:-12px;
    right:-12px;
    border-radius: 50%;
    border:2px solid $green-500;
    width:44px;
    height: 44px;
    z-index:2;
    display:flex;
    align-items: center;
    justify-content: center;
    svg{
        width:32px;
        height: 32px;
        fill:$green-500;

    }

}

.fill-info-card{
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(89, 89, 89, 0.06);

}

.basket-summary-card{
    border:1px solid $green-500;
    border-radius: 8px;
    position:sticky;
    top:0px;
    .price-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:8px;
        span{
            color:$green-500;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            strong{
                display: flex;
            }
        }
        .price-value{
            color: $grey_color;

        }
        svg{
            width: 20px;
            height: 20px;
        }
    }
    a{
        color: $secondary-dark-font-color;
        text-decoration: underline;
    }
    .ant-divider {
        margin: 8px 0 8px 0;
    }

}

.spinner-wrapper{
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 60px;
    div{
        width: 100%;
    }
}

.save-card-info{
    display: flex;
    align-items: center;
    svg{
        fill:$green-500;
        width: 20px;
        height: 20px;
    }
}

.free-payment-info{
    border:1px solid $blue-400;
    border-radius: 4px;
    padding:12px;
    color: $blue-600;
    background-color: $blue-100;
    display: flex;
    align-items: center;
    width:100%;
    margin-bottom: 8px;
    svg{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.discount-title{
    margin-top:16px;
    margin-bottom:16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:$water-green-500;
    font-size: 18px;
    font-weight: 700;
    svg{
        fill:$water-green-500
    }
}

.discount-input{
    display: flex;
    align-items: start;
    gap: 8px;
    .ant-form-item {
        margin-bottom: 8px;
    }
    .discount-warning{
        margin-top: 8px;
        color: #ff4d4f;
        height: 24px;
    }
}
.discount-warning{
    color: #ff4d4f;
}

.payment-completed-wrapper{
    div{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media all and (max-width : 576px) {
    // .hidden-sm{
    //     display: none;
    // }
}

@media all and (min-width : 576px) {
    .hidden-md{
        display: none;
    }
}

.package-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 16px;
    .package-name{
        font-size: 20px;
        font-weight: 700;
        color: $water-green-500;
        margin-bottom: 16px;
        
    }
    .employee-label{
        display: flex;
        font-size: 16px;
        svg{
            fill: $blue-500;
            width: 20px;
            height: 20px;
            margin-right:4px;
        }
    }
    .price{
        color: $grey_color;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
    }
    
}

.addressField{
    margin-bottom: 20px;
    .title{
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
    }
    .value{
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }
}

.ant-alert-info.info-alert-box{
    background-color: $blue-100;
    border: 1px solid $blue-400;
    border-radius: 4px;
    margin-top: 16px;
    .ant-alert-message {
        color:$blue-600;
    }
    .ant-alert-icon {
        color: $blue-600;
    }
}

.ant-alert-info.warning-alert-box{
    background-color: $green-100;
    border: 1px solid $green-400;
    border-radius: 4px;
    margin-top: 16px;
    .ant-alert-message {
        color:$green-400;
    }
    .ant-alert-icon {
        color: $green-400;
    }
}

.card-toggle{
    color: $blue-600;
    display: inline-flex;
    cursor: pointer;
    svg{
        fill: $blue-600;
        width: 18px;
        height: 18px;
        margin-right: 4px;
    }

}

.saved-card-wrapper{
    display: flex;
    .card-name{
        color:$grey_color;
        font-weight: 500;
    }
    .card-info{
        color:$text-50;
        font-size: 14px;
        line-height: 18px;
    }
    img{
        height: 20px;
    }
}
.tl-price-payment{
    color: #595959;
    height: 20px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    text-align: end;
}