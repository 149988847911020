@import './color.scss';
.update-user-draw{
   // min-height: 100vh !important;
    height: auto !important;
}
.add-user-draw{
    min-height: calc(100% + 140px) !important;
    height: auto !important;
}
.add-user-draw-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.add-user-draw-back-text{
    display: flex;
    cursor: pointer;
    color: $blue_text_color;
    svg{
        font-size: 15px;
        position: relative;
    }
    span{
        font-size: 16px;
    }
}
.add-user-draw-title{
    display: flex;
    h1{
        font-size: 24px;
        font-weight: 700;
        color: $grey_color;
    }
}
.add-user-document-checkbox{
    padding-left: 10px !important;
    padding-top: 5px !important;
}
.add-user-close-icon{
    cursor: pointer;
    position:absolute;
    top:15px;
    right:15px;
}
.add-user-draw-required-field-text{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: $grey_color;
    font-size: 16px;
    line-height: 19px;
    padding-top: 5px;
}
.add-user-draw-phone-input{
    margin-right: 10px;
}
.add-user-draw-phone-ext-input{
    margin-right: 3px;
}
.ant-form-item-required::before{
    display: none !important;
}
.add-user-draw-add-image{
    cursor: pointer;
    display: flex;
    span{
       font-size: 16px;
       color: $blue_text_color;
    }
    .ant-image-img{
        position: relative;
        top: 4px;
        max-width: 20px;
        max-height: 20px;
        margin-right: 5px;
    } 
}
.add-user-terms-container{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 120px;
    width: 100%;
    height: 80px;
    bottom: 114px;
    background-color: $white_color;
    border-top: 1px solid $grey2_color;
    span{
        color: $grey_color;
    }
    
}
a.terms-text-link{
    text-decoration: underline;
    color: $grey_color;
    font-weight: 600;
    &:hover{
        color:$blue_text_color;
        text-decoration: underline;
    }
}
.add-user-draw-footer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 113px;
    padding-left: 17px;
    padding-top: 36px;
    background-color: $blue_color;
    bottom: 0;    
}
.add-expense-draw-footer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 26px;
    background-color: #d7f0ee;
    bottom: 0;
    left: 0;
}
.add-user-draw-footer-end{
    position: absolute;
    display: flex;
    padding-right: 16px;
    right: 0;
}
.hide-tab{
    padding-bottom: 50px;
    .ant-tabs-nav-wrap{
        display: none !important;

    }
}
.table-details .ant-form label{
    font-weight: 400;
}
.success{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    .ant-image-img{
        max-width: 50px;
        max-height: 50px;
    }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: none !important;
}

.error-alert-box{
    margin-bottom: 16px !important;
    .ant-alert-message {
        color:$red-600 !important;
    }
    svg{
        fill: $red-600;
    }
}