@import './color.scss';

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon{
    margin-right: 3px !important;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description{
    padding-bottom: 0;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $grey5_color !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: $blue_text_color !important;

}
.ant-steps-item-container{
    min-height: 90px;
}

.step-primary-tail{
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot ,.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        background: $blue_text_color !important;
    }
    .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot{
        left: 0px !important;
    }
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $secondary-green-background !important;
  }
}
.horizontal-step{
    .ant-steps-item-title{
        line-height: 30px !important;
        white-space: pre-wrap;
    }
   .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
       color:$blue_text_color !important;
   }
    .ant-steps-item-finish{
        .ant-steps-item-icon{
            background-color:$blue_text_color !important;
            border-color:$blue_text_color !important;
        }
     
    }
 
}
.left-step{
    display: flex;
    .ant-steps-item-title{
        font-size: 16px;
        color: $grey5_color;
        line-height: 400;
    }
    .ant-steps-item-content{
        padding-top: 1px;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        color: $white_color;
        border-width: 2px;
        line-height: 28px;
        background-color: $grey5_color;
        border-color: $grey5_color;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        font-size: 14px;
        color: $white_color;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        color: $grey5_color;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        color: $white_color;
        border-width: 2px;
        line-height: 28px;
        background-color: $grey5_color;
        border-color: $grey5_color;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
        color: $white_color;
    }

    .ant-steps-item-active{
        .ant-steps-item-icon{
            color: $blue_text_color !important;
            border-color: $blue_text_color;
            background: transparent;
            line-height: 28px;
        }
        .ant-steps-icon{
            color: $blue_text_color !important;
        }
        .ant-steps-item-title{
            color: $blue_text_color !important;
        }
    }
   
    .sub-tab{
        padding: 0 !important;
        li{
            font-size: 14px;
            position: relative;
            left: 20px;
            color: $grey5_color;
        }
        .sub-tab-active::before{        
            content: '✓';
            margin-right: 10px;
          
        }
        li.sub-tab-active{
            color: $blue_text_color;
            position: relative;
            left: 0;
            list-style: none;
        }
    }
    .sub-tab-hide-icon{
        padding: 0 !important;
        li{
            font-size: 14px;
            position: relative;
            color: $grey5_color;
            list-style: none;
        }
      
        li.sub-tab-active{
            color: $blue_text_color;
            position: relative;
            left: 0;
            list-style: none;
        }
       
    }
}
.time-off-step{
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
        line-height: 30px;
    }
    .ant-steps-item-title {
        font-size: 13px !important;
    }
    .ant-steps-item-description {
        font-size: 14px;
    }
}