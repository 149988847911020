@import './color.scss';

.education-modal, .form-modal{
    .ant-modal-body, .ant-modal-footer, .ant-form{
        padding: 0 !important;
    }
    .ant-modal-footer{
        display: none;
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover{
        border-color: $primary-green-background;
    }
    .ant-upload-drag-icon{
        margin-bottom: 8px !important;
        svg{
            width: 36px;
            height: 36px;
        }
        color: $primary-green-background;
    }
    .ant-upload-list-text .ant-upload-list-item-card-actions{
        display: none;
    }
    .ant-spin{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $shadow_color6;
        z-index: 1;
        cursor: not-allowed;
        &-dot, &-text{
            position: relative;
            top: 50%;
        }
        &-text{
            font-size: 16px;
            color: $primary-green-background;
        }
    }
    
    &-header{
        display: flex;
        justify-content: space-between;
        background-color: $primary-green-background;
        color: $white_color;
        padding: 16px;
        &-title{
            font-size: 20px;
            svg{
                position: relative;
                top: 8px;
                margin-right: 1px;
                width: 32px;
                padding: 3px 0px;
                height: 32px;
            }
        }
        &-exit{
            .transparent-btn{
                font-size: 16px;
                color: $white_color;
                &:hover{
                    color: $white_color;
                    font-weight: 600;
                }
            }
            svg{
                position: relative;
                top: 8px;
            }
        }
    }
    &-form{
        &-title{
            font-weight: 600;
        }
        .ant-form-item{
            label{
                font-size: 14px;
            }
        }
        .ant-radio-group.ant-radio-group-outline{
            display: flex;
        }
        padding: 16px;
    }
}
.fixed-modal-buttons{
    position: absolute;
    bottom: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    right: 0;
    left: 0;
    padding: 0px 16px;

}
.inventory-import-modal{
    min-height: 420px !important;
    .ant-steps-item-container{
        min-height: 1px;
    }
    .successful-wrapper{
        padding: 0 !important;
        .img-wrapper{
            height: auto;
            width: 100%;
            img{
                width: 100px;
                height: 100px;
            }
        }
        .successful-header-text{
            font-size: 24px;
        }
        .success-data-message, .error-data-message{
            display: flex;
            font-size: 16px;
            text-align: left;
            svg{
                margin-right: 4px;
            }
        }
        .success-data-message{
            color: $green3_color;
            padding-bottom: 4px;
        }
        .error-data-message{
            color: $red_color;
        }
    }
}
.inventory-error-list-modal{
    height: 470px;
}

// import modal

.import-modal-header{
    background:white !important;
}
.import-modal-header{
    color: $grey-color !important;
}
.import-modal-close{
    color: $grey-color !important;
}
.import-color-grey{
    color: $grey-color !important;
}
.import-download-section{
    display:flex;
    flex-direction:column;
    gap:6px;
}
.import-download-button{
    max-width: max-content;
    margin: auto;
}
.import-modal-upload-section{
    background: #FDFDFD !important;
    .ant-upload-btn{
        padding-bottom:0 !important;
    }
}
.import-modal-drag-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px !important;
}
.import-modal-button{
    border:none !important;
    box-shadow: none;
  }
  .ant-steps-item.inventory-download-template.ant-steps-item-active .ant-steps-item-title {
    white-space: nowrap;
  }