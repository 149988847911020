@import './color.scss';

.time-off-table-end {
    justify-content: flex-end;
}
.custom-input-group{
    .ant-input-group-addon{
        .ant-form-item{
            margin-bottom:0;
        }
    }
    &.d-flex{
        .ant-form-item-control-input-content{
            display: flex;
        }
        &.align-items-center{
            .ant-form-item-control-input-content{
                align-items: center;
            }
        }

    }

    .ant-select{
        min-width: 100px;
        &.ant-select-focused{
            .ant-select-selector{
                color: unset;
            }
        }
    }
}
.max-width-50per{
    max-width: 50%;
}

.timeoff-type-steps {
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        .ant-steps-icon {
            color: white;
        }
        background : $blue-500 !important;
        border-color: $blue-500;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: $blue-500;
        border-color: $blue-500;
        svg{
            fill:white;
        }
    }
    .ant-steps-item-process.ant-steps-item-active  > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: $blue-500;
    }
}

.ant-form-item-label label {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.custom-label-tooltip{
    .ant-form-item-label {
        overflow: unset;
    }
    .ant-tooltip-placement-top .ant-tooltip-arrow {
        left: 45%;
        transform: translateX(-45%);
    }
}
.time-off-applied-text-yearly{
    font-size: 16px;
    color : #76777A;
}
.text-switch-container{
    display: flex;
    align-items: center;
    span{
        padding-right: 16px;
    }   
}
.summary-left-col{
    border-right: 1px solid $grey2_color;
    flex:1;
}
.summary-right-col{
    flex:1;
    padding-left: 16px;
}
.field-name{
    font-weight: 600;
}
.field-value{
    margin-bottom: 16px;
    overflow-y: auto;
    max-height: 50px;
    .ant-tag{
        margin-bottom:4px;
    }
}

.add-timeoff-type-draw-footer{
    position: absolute;
    display: flex;
    width: 100%;
    height: 113px;
    padding-left: 17px;
    padding-top: 36px;
    bottom: 0;
}

.no-label-input-in-row{
    margin-top:30px;
}

.dotted-underline {
    min-width: 70px;
    border-bottom: 1px  dotted $grey2_color;
    text-align: center;
}
.me-3{
    margin-right: 12px !important;
}
.timeoff-types-table-dropdown-menu{
    .ant-btn{
        color: $grey17_color;
    }
    .delete-btn{
        color: $red3_color;
    }
}

.timeoff-type-table-details{
    .fixed-btn{
        width: 760px;
        padding-bottom: 0px;
        .add-user-draw-footer{
            background-color: white;
        }
    }
}

.timeofftype-name-col{
    padding-left: 68px !important;
    width: 580px !important;
    div{
        div{
            margin-left:20px;
        }
    }
}

.birthday-timeoff-modal{
    .ant-modal-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
    }
    .ant-modal-body{
        padding: 12px 24px 8px 24px;
    }
    .ant-modal-header{
        border-bottom: 0;
    }
    .ant-modal-footer{
        border-top:0;
        padding: 10px 24px 24px 24px;
    }
}

.is-changed-group-input{
    .ant-input{
        border: 1px solid #39B2AC !important;
        border-radius: 2px;
    }
}

.is-changed-group-select{
    .ant-select-selector{
        border: 1px solid #39B2AC !important;
        border-radius: 2px;
    }
}

.timeoff-type-drawer-footer{
    border-top:1px solid #E4E4E4;
}

.annual-leave-settings {
    margin-bottom: 24px;
    max-width: 100%;
    
    .annual-leave-list {
        margin-bottom: 16px;
    }

    .error-message {
        color: #ff4d4f;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 8px;
    }
    
    .annual-leave-item {
        margin-bottom: 12px;
        
        .ant-input {
            width: 100%;
            
            &.input-error {
                border-color: #ff4d4f;
                background: #fff2f0;
                border: 1px solid #ff4d4f !important;
                
                &:hover {
                    border-color: #ff7875;
                    border: 1px solid #ff4d4f !important;

                }
                
                &:focus {
                    border-color: #ff7875;
                    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
                    outline: 0;
                }
            }
        }

        .text-center {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color : #76777A;

            .white-btn {
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }
        }

        &:hover {
            .text-center .white-btn {
                opacity: 1;
            }
        }
    }
    
    .add-annual-leave-btn {
        height: 40px;
    }
}

