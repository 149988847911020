@import './color.scss';

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    border: 1px solid $grey2_color !important;
}
 .ant-table-tbody > tr > td{
    padding: 8px 16px !important;
}
tr.ant-table-measure-row {
    display: none;
}
.ant-table-tbody > tr:last-child > td {
    border-bottom: 0 !important;
}
.table-hide-all-buttons{
    .table-filter{
        display: none !important;
    }
}
.blue-table{
    .table-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
        background-color: $blue_color;
        color: $grey_color !important;
        padding-left: 30px;
    }
    .table-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
        padding-left: 30px !important;
        font-size: 16px !important;
        color: $grey_color !important;
    }
}
.table-container{
    .table-search{
        .ant-input {
            background-color: $white_color;
            color: $grey_color;
            margin-top:1px;
        }
        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover { 
            border-color: $blue_text_color !important;
        }
        .ant-input-prefix {
            margin-top: 4px;
        }
        .ant-input-suffix {
            margin-top: 4px;
        }
        @media all and (min-width: 768px) and (max-width: 830px) {
        .ant-input-affix-wrapper{
            width: 310px !important;
        }
        }
        @media all and  (max-width: 767px) {
            .ant-input-affix-wrapper{
                width: 100% !important;
            }
        }
        .ant-input-affix-wrapper{
            width: 380px;
            height: 32px;
            background-color: $white_color;
            border-color: $grey5_color;
            padding-bottom: 0;
            padding-top: 0;
            input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $secondary-dark-font-color;
                opacity: 1; /* Firefox */
            }
            
            input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $secondary-dark-font-color;
            }
            
            input::-ms-input-placeholder { /* Microsoft Edge */
                color: $secondary-dark-font-color;
            }
        }
        .ant-input-affix-wrapper:hover {
            border-color: $blue_text_color !important;
        }
        .ant-input-prefix, .ant-input-suffix {
            svg{
                position: relative;
                top: -2px;
                color: $primary-green-font-color;
                font-size: 15px;
            }
        }
        ::placeholder{
            color : $grey5_color;
        }
    }
    .table-filter{
        width: 100%;
        display: flex;
        min-height: 32px;
        .ant-btn{
            padding: 0;
            text-align: center;
        }
        .transparent-btn{
            color:$secondary-dark-font-color;
            .anticon{
                color:$primary-green-font-color;
            }
        }
        .transparent-btn:hover{
            color:$primary-green-font-color;
        }
    }
    &.table-without-menu{
        padding-top:0;
        .table-filter{
            min-height: unset;
        }
        .table-container{
            padding-top:0;
        }
    }
    padding-top: 10px;
    .table-avatar{
        border-radius: 50%;
        border: 1px solid $grey_color;
        width: 40px;
        height: 40px;
        @media all and (max-width: 765px) {
            display: none;
        }

    }
    .ant-table-thead {
        tr > th {
            background-color: $white_color;
        }
        .ant-table-selection-column .ant-checkbox-inner {
            border: 2px solid $checkbox_th_color;
            border-radius: 1px;
            background: transparent;
           }
           span{
            font-size: $primary-font-size;
            font-weight: bold;
            color: $secondary-dark-font-color;
        }
        .ant-table-column-title{
            //flex: none;
            flex: unset;
        }
        .ant-table-filter-column, .ant-table-column-sorters {
            justify-content: flex-start;
        }
        .ant-table-column-sorter {
            position: relative;
            top: -1px;
            padding-left: 5px;
            padding-right: 5px;
        }
        .ant-table-column-sorter-full{
            span{
                position: relative;
                top:-0.1px;
                margin-top: -4px;
                font-size: 10px;
            }
        }
    }
 
    .ant-table-tbody{
        .ant-table-selection-column .ant-checkbox-inner {
            border: 2px solid $breadcrump_color;
            border-radius: 1px;
            background: transparent;
           }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent;
        border: 2px solid $blue_text_color ;
        border-radius: 1px;

    }   
    .ant-checkbox-inner::after {
        top: 47.7%;
        left: 28.9%;
        width: 3.9px;
        height: 7px;
        border-color: $blue_text_color !important;
        border-radius: 32%;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner{
        border-color: $blue_text_color !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: $blue_text_color;
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover, .ant-table-filter-trigger-container:hover,  .ant-table-filter-trigger:hover,.ant-table-filter-trigger {
        background-color: $white_color;
    }
    .ant-table-filter-trigger {
        background-color: $white_color;
    }
    .ant-table-filter-trigger .anticon {
        top: 48%;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
        border-right: 0;
        background-color: $white_color;
        border-color: $nav_border_color;
        color: $secondary-dark-font-color
    }
    
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
        border:0;
        border-color:transparent;
        border-bottom: 1px solid $grey2_color;
        font-size: 0.95rem;
        font-weight: bold;
        color: $secondary-dark-font-color

    }
    .ant-table-row:hover, .ant-table-row::after{
        z-index: 321312;
    }
    .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
        background-color: $grey10_color !important;

    }

    .details-text{
        cursor: pointer;
        color: $secondary-dark-font-color;
        font-weight: bold;
    }
    a:hover.details-text{
        text-decoration: underline;
    }
    .icon-size{
      svg{
        font-size: 15px;
      }  
    }
    .rol-icon{
        svg{
            font-size: 15px;
        }
    }
    .table-report-image{
        display: flex;
        top: 20px;
        .ant-image-img{
            border-radius: 20px;
        }
        .close-left{
            position: absolute;
            right: 20px;
        }
        .close-left-2{
            position: absolute;
            right: 40px;
        }
        .close-left-end{
            position: absolute;
            right: 10px;
        }
        .icon{
            position: relative;
            top: 12px;
            right: 14px;
            svg{
                color : $blue_text_color;
                font-size: 14px;
            }
        }
        h4{
            position: relative;
            top: 8px;
            font-size: 14px;
       }
       .lenght3{
           position: relative;
           right: 20px;
       }
       .lenght1{
        position: relative;
        left: 10px;
    }
    }
    .more-icon{
        font-size: 32px;
        color: $secondary-dark-font-color;
    }
    .ant-pagination-total-text {
        margin-top: 2px;
        margin-right: 18px;
    }

}
.table-border-bottom-0{
    .ant-table-cell{
        border: 0 !important;
    }
    .ant-checkbox{
        display: none !important;
    }
}
.imageNameActionColumn{
    display: flex;
    align-items: center;
    .details-text{
        margin-left:15px;
        @media all and (max-width: 765px) {
            margin-left: 0 !important;
        }
    }
    .inventory-text{
        display: flex;
        svg{
            margin-right: 10px;
            width: 20px;
            height: 20px;
            color:$black_color;
        }
    }
    .action-menu{
        margin-left: auto;
    }
   a:hover{
      text-decoration: underline;
   }
}
.table-center-text{
    display: flex;
    width: 100%;
    height: 39px;
    align-items: center;
}
.connected-drawer-search-input{
    max-width: 384px;
  }
  
.filter-options-div{
    max-height: 150px;
    overflow-y: auto;
    margin:10px 0 10px 0;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow:$primary-green-box-shadow-color !important;
}