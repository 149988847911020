@import './color.scss';
@import "./break-points.scss";
html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family;
  font-size:16px !important;
}
html {
  --antd-wave-shadow-color: none !important;
}
#root{
  height: 100%;
}
.ant-layout {
  // // min-height: 100vh;
  // height: 100%;
  background-color: $primary-light-background;
}
.layout-bg{
    background-color: $background_color !important;
}
.ant-breadcrumb > span:last-child a{
  color: $breadcrump_color;
}
.ant-breadcrumb a{
  color: $secondary-dark-font-color;
}
.ant-breadcrumb a:hover{
  color: $blue_hover_color;
}
.react-tel-input .flag-dropdown {
  background-color: $white_color !important;
}
.flag-disable .flag-dropdown{
  cursor: not-allowed !important;
  background-color: $grey3_color !important;
}
.clickable{
  cursor: pointer;
}
.position-relative{
  position: relative;
}
//Flex
.space-between{
  display: flex;
  justify-content: space-between;
}
.flex-start{
  display: flex;
  justify-content: flex-start !important;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}
.flex-center{
  display: flex;
  justify-content: center;
}
.flex-align-center{
  display: flex;
  align-items: center;
}
.flex-class{
  display: flex;
  width: 100%;
}

.text-center{
  text-align: center;
}
.align-self-center{
  align-self: center;
}
.text-left{
  text-align: left !important;
}
//unicons iconları buton icinde kullanımı
.unicons-page-dropdown{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  svg{
    margin-right: 5px;
    width: 16px;
    height: 18px;
  }


}
.svg-class{
  justify-content: center;
  text-align: center;
  align-items: center;
  svg{
    width: 18px;
    height: 18px;
  }
  span{
   //margin-top: 3px;
   margin-left: 3px;
  }
}
svg.svg-primary-icon{
  width: 18px;
  height: 18px;
  path{
    color: $blue_text_color !important;
  }
}
.loading-option{
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-container{
  padding-left: 24px;
  padding-right: 24px;
  .safe-info{
    width: 322px;
    height: 29px;
    border: 1px solid $grey2_color;
    padding-left: 24px;

    h1{
    font-size: 14px;
    color: $grey2_color;
    padding-top: 3px;
    svg{
      font-size: 15px;
    }
    }
    .safe-hr{
      justify-content: center;
      margin-top: 2px;
      margin-left: 5px;
      width: 1px;
      height: 23px;
      background-color: $grey2_color;
    }
  }
  h1.page-title-1{
    position: relative;
    height: 10px;
    font-size: 24px;
    color: $grey_color; 
  }
}
.ant-layout-content{
  background-color: $primary-light-background !important;
  
}
.content-with-bar>.ant-layout-content{
  padding-top:106px !important;
}
@media all and (max-width:$custom-breakpoint-xl){
  .content-with-bar>.ant-layout-content{
    padding-top:146px !important;
  }
}
.breadcrump{
  span{
    color: $breadcrump_color;

  }
  a{
    color:$breadcrump_color;
  }
}
.toggle-text{
  font-size: 16px;
  font-weight: 400;
}
.ant-switch-checked{
  background-color: $blue_text_color !important;
}
.w-100{
  width: 100%;
}
.w-auto{
  width: auto !important;
}
.w-50{
  width: 50%;
}
.m-w-200{
  min-width: 200px;
}
.m-w-220{
  min-width: 220px;
}
.m-w-75{
  min-width: 75px;
}
.m-w-180{
  min-width: 180px;
}
.m-w-110{
  min-width: 110px;
}
.max-w-100{
  width: 100% !important;
  max-width: 100% !important;
}
.max-w-330{
  max-width: 330px !important;
}
.max-w-478{
  width: 478px !important;
}

.w-390{
  width: 390px;
}
.w-230{
  width: 230px;
}
.w-210{
  width: 210px;
}
.w-200{
  width: 200px;
}
.w-190{
  width: 190px;
}
.w-180{
  width: 180px;
}
.m-w-180{
  min-width: 180px;
}
.w-178{
  width: 178px;
}
.w-120{
  width: 120px;
}
.w-75{
  width: 75px;
}
.w-52{
  width: 52px;
}
.w-90{
  width: 90px;
}
.min-height-500{
  min-height: 500px;
}
.h-82vh{
  height: 82vh;
}
.h-70vh{
  height: 70vh;
}
.h-60vh{
  height: 60vh;
}
.h-50vh{
  height: 50vh;
}
.h-45vh{
  height: 45vh;
}
.h-40vh{
  height: 40vh;
}
.h-30vh{
  height: 30vh;
}
.h-40{
  height: 40px;
}
.h-32{
  height: 32px !important;
}
.h-auto{
  height: auto;
}
.h-32{
  height: 32px;
}
.f-24{
  font-size: 24px;
}
.f-18{
  font-size: 18px;
}
.f-16{
  font-size: 16px;
}
.f-14{
  font-size: 14px;
}
.f-24{
  font-size: 24px;
}
.fw-600{
  font-weight: 600;
}
.fw-500{
  font-weight: 500;
}
.f-w-400{
  font-weight: 400;
}

.line-10{
  line-height: 10px;
}
.line-20{
  line-height: 20px;
}
.green{
  color: $green_color !important;
}
.black{
  color: $grey_color !important;
}
.grey12{
  color: $grey12_color !important;
}
.text-opaque{
  color : $secondary-dark-font-color;
}
.blue{
 color:$blue_text_color;
}
.red{
  color : $red_color !important;
}
.yellow{
  color: $yellow_color;
}
.bg-white{
  background-color: $white_color;
}
.disabled{
  cursor:not-allowed !important;
}
.pad-top-2{
  padding-top: 2px;
}
.pad-top-5{
  padding-top: 5px;
}
.pad-top-7{
  padding-top: 7px;
}
.pad-top-10{
  padding-top: 10px;
}
.pad-top-15{
  padding-top: 15px;
}
.pad-top-20{
  padding-top: 20px;
}

.pad-top-40{
  padding-top: 40px;
}
.pad-top-75{
  padding-top: 75px;
}
.pad-6{
  padding-bottom: 6px;
}
.pad-12{
  padding-bottom: 12px;
}
.pad-15{
  padding-bottom: 15px;
}
.pad-21{
  padding-bottom: 21px;
}
.pad-24{
  padding-bottom: 24px;
}
.pad-25{
  padding-bottom: 25px;
}
.pad-27{
  padding-bottom: 27px;
}
.pad-30{
  padding-bottom: 30px;
}
.pad-36{
  padding-bottom: 36px;
}
.pad-55{
  padding-bottom: 55px;
}
.pad-100{
  padding-bottom: 100px;
}
.pad-left-60{
  padding-left: 60px;
}
.pad-left-30{
  padding-left: 30px;
}
.pad-left-28{
  padding-left: 28px;
}
.pad-left-24{
  padding-left: 24px;
}
.pad-left-20{
  padding-left: 20px;
}
.pad-left-16{
  padding-left: 16px;
}
.pad-left-12{
  padding-left: 12px;
}
.pad-left-10{
  padding-left: 10px;
}
.pad-left-3{
  padding-left: 3px;
}
.pad-right-360{
  padding-right: 360px;
}
.pad-right-200{
  padding-right: 200px;
}
.pad-right-36{
  padding-right: 36px;
}
.pad-right-24{
  padding-right: 24px;
}
.pad-right-18{
  padding-right: 18px;
}
.pad-right-16{
  padding-right: 16px;
}
.pad-right-12{
  padding-right: 12px;
}
.pad-right-10{
  padding-right: 10px;
}
.pad-right-6{
  padding-right: 6px;
}
.pad-right-0{
  padding-right: 0px !important;
}
.pad-0{
  padding: 0 !important;
}
.px-15{
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.mar-0{
  margin: 0 !important;
}
.ml-30{
  margin-left:30px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.margin-left-18{
  margin-left: 18px;
}
.margin-left-12{
  margin-left: 12px;
}
.margin-r-12{
  margin-right: 12px;
}
.margin-r-24{
  margin-right: 24px;
}
.margin-r-33{
  margin-right: 33px;
}
.margin-r-36{
  margin-right: 36px;
}
.margin-b-0{
  margin-bottom:0px;
}
.margin-b-12{
  margin-bottom: 12px;
}
.m-t-10{
  margin-top: 10px;
}
.m-t-35{
  margin-top: 35px;
}
.m-t-24{
  margin-top: 24px;
}
.my-15{
  margin-top:15px;
  margin-bottom:15px;
}
.mx-0{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.font-w-700{
  span{
    font-weight: 700;
  }
  font-weight: 700;
}
.right-text{
  text-align: right !important;
}
.left-text{
  text-align: left !important;
}
.salary-count-message{
  padding-bottom: 20px;
  span{
    color: $blue_text_color;
  }
}

.align-items-center{
  align-items: center !important;
}
.image-border {
  border: 1px solid $grey2_color;
  border-radius: 55%;
}

//Global export modal
.export-modal{
  display: flex;
  max-width: 265px;
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .export-model-item{
    cursor: pointer;
    padding-bottom: 20px;
   span{
    display: flex;
    font-size: 14px;
    color: $grey_color;
    justify-content: left;
    padding-top: 7px;
   }
  }
}


//Global divider
.ant-divider{
  color: $grey2_color !important;
  border-color: $grey2_color !important;
}

//Global drawer
.ant-drawer-right.ant-drawer-open.ant-drawer-without-shadow{
  width: 0 !important;
}

.ant-drawer-body {
  padding:0;
}

//Global circular chart
.chart-card{
  width: 100%;
  max-width: 175px;
  height: 100%;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.14) !important;
  // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
  margin-right: 33px;
  margin-bottom: 33px;
  
  .ant-card-body{
    padding-top: 11px !important;
    padding:0;
  }
  h1{
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
  }

    h2{
      font-size: 16px;
      line-height: 0;
    }
    h3{
      font-size: 16px;
      text-align: center;
    } 
    .ant-card-bordered{
      border: none !important;
    }

}

.permission-body{
  .chart-card{
    border: 1px solid rgba(0, 0, 0, 0.14) !important;
    box-shadow: none;
    margin-right: 0;
    margin-bottom: 0;
    .ant-card-body{
      padding: 15px 0;
    }
  }
  .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border-bottom: 0;
  }

  .ant-list-pagination {
    text-align: left;
  }
}
.ant-list-empty-text{
  padding: 0 !important;
}
.dynamic-table-filter-container{
  min-width: 180px;
}
.table-empty-container{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 55px;
  padding-bottom: 91px;
    .table-empty-image{
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .table-empty-text{
      display: flex;
      width: 100%;
      justify-content: center;
      h1{
        color: $grey2_color;
        font-size: 18px;
        line-height: 22px;
      }
    }
   
}
.more-toggle {
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  color: $blue_text_color;
  margin-right: 10px;
}
.ant-tabs-extra-content{
  flex: auto;
  
}
.tab-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  
}
//CHECKBOX
//.ant-tree-switcher {
  // display: none;
//}
.ant-form{
  padding-right: 10px;
}
.ant-checkbox-checked::after, .ant-tree-checkbox-checked::after{
  border-color: $blue_text_color;
}
.ant-checkbox-indeterminate .ant-checkbox-inner{
  border-color: $blue_text_color !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after{
  background-color:  $blue_text_color !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner{
  border-color: $blue_text_color !important;
}
.ant-checkbox .ant-checkbox-inner, .ant-tree-checkbox .ant-tree-checkbox-inner {
  border: 2px solid $grey2_color;
  border-radius: 1px;
  background: transparent;
}
 .ant-checkbox-checked .ant-checkbox-inner, .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: transparent;
  border: 2px solid $blue_text_color !important;
  border-radius: 1px;
}
.ant-checkbox-input:focus, .ant-tree-checkbox-input:focus{
  border-color: $blue_text_color !important;
} 

.ant-checkbox-inner::after, .ant-tree-checkbox-inner::after {
  top: 47.7%;
  left: 28.9%;
  width: 3.9px;
  height: 7px;
  border-color: $blue_text_color !important;
  border-radius: 32%;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after{
  background-color: $blue_text_color;
}
.ant-checkbox-disabled{
  .ant-checkbox-inner{
    border-color: $grey2_color !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: $grey2_color !important;
  }
  .ant-checkbox-inner::after{
    border-color: $grey2_color !important;
  } 
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{
  background-color: $blue_color;
}
.ant-select-tree-switcher{
  width: 12px;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner{
  background-color: $blue_text_color !important;
  border-color: $blue_text_color !important;
}
.ant-select-tree-checkbox-checked::after{
  border-color: $blue_text_color !important;
}
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner{
  border-color: $blue_text_color !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover{
  background-color: transparent !important;
}
.ant-tree-checkbox-disabled{
  border-color: $grey2_color !important;
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner{
    border-color: $grey2_color !important;
  }
}

.secondary-dark-text{
  color:$secondary-dark-font-color;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after{
  background-color: $blue_text_color;
}
//CHECKBOX CHANGE DATA
.is-checked{
  .ant-checkbox-checked::after{
    border-color: $green_color !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: $green_color !important;
  }
  .ant-checkbox .ant-checkbox-inner {
    border: 2px solid $green_color;
    border-radius: 1px;
    background: transparent;
  }
   .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border: 2px solid $green_color !important;
    border-radius: 1px;
  }
  .ant-checkbox-input:focus{
    border-color: $green_color !important;
  } 
  .ant-checkbox-inner::after {
    top: 47.7%;
    left: 28.9%;
    width: 3.9px;
    height: 7px;
    border-color: $green_color !important;
    border-radius: 32%;
  }
  
  span{
    color: $green_color;
  }
}
.is-changed-radio.ant-radio-wrapper-checked{
  span{
    color: $green_color;
  }
  .ant-radio-inner{
    border-color: $green_color !important;
  }
  .ant-radio-inner::after{
    background-color: $green_color !important;
  }
}
.ant-radio-inner{
  border-color: $green_color !important;
}
.ant-radio-inner::after{
  background-color: $green_color !important;
}
.tab-loading{
  display: flex;
  height: 56vh !important;
  justify-content:center;
  text-align: center;
  align-items: center;
  color:$primary-green-font-color;
  span{
    margin-right: 10px;
  }
}
.ant-spin-dot-item{
  background-color: $primary-green-background;
}

.green-text{
  color: $green_color;
}

.ant-form-item-label label{
  font-weight: bold !important;
}
.ant-radio-wrapper{
  padding-bottom: 10px;
}
.link-text{
  font-size:16px;
  color:$blue_text_color;
  cursor: pointer !important;
}
//FILTER
.ant-table-filter-dropdown{
 // min-height: 250px;
 // min-width: 250px;
 // max-width: 250px;
 //padding: 10px 76px 50px 10px !important;
 padding: 10px 10px 40px 10px !important;
}
.visibility-column-filter-dropdown{
  min-width: 180px;
  background-color: $white_color;
}
.filter-button-class{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}
// SELECT
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: $blue_text_color !important;
  box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: $blue_text_color !important;
  box-shadow: none;
}
  .detail-tabs-with-pad{
    .ant-tabs > .ant-tabs-nav{
      margin-right: 24px;
    }
  }
  
.ant-select{
  color: $grey_color;
}
.ant-modal-wrap {
  z-index: 10000;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
 background-color: $grey5_color; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey2_color; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey2_color; 
}

.lang-switch{
  font-weight: bold;
  font-size: 0.95rem;
  color:$secondary-dark-font-color;
  .anticon{
    color:$primary-green-font-color;
  }
}
.lang-switch:hover, .lang-switch:focus, .lang-switch:active {
  cursor: pointer;
  color:$primary-green-font-color;
}
.no-pointer{
  cursor: auto !important;
}

.dynamic-modal-button{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}
.show-modal{
  display: flex !important;
}
.hide-modal{
  display: none !important;
}
.verify-link{
  color: $blue_text_color;
  text-decoration: none;
  font-weight: 600;
}a:hover{
  color: $blue_hover_color;
}
.page-tabs{
  .ant-tabs-tab{
      font-size: 16px;
  }
  .ant-tabs-nav-list{
      color: $grey_color;
      padding-bottom: 3px;
      border-bottom: 1px solid $grey2_color !important;

  }
  .ant-tabs-nav .ant-tabs-tab-active{
      margin-bottom: 3px;
  }
 .ant-tabs-tab + .ant-tabs-tab{
      margin: 0 0 0 32px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      color: $blue_text_color !important;
  }

}
.global-error-badge{
  .ant-badge{
    background-color: $red2_color;
    color: $white_color;
    padding: 8px 24px 8px 24px;
    border-radius: 5px;
    font-size: 16px;
  }
}
.react-player iframe {
  pointer-events: none; 
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.react-player-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 100%;
  aspect-ratio: 16/9;
  video{
    // object-fit: contain;
    background: $black_color;
  }
  .center-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem; 
    cursor: pointer;
    color: $blue_hover_color;
    opacity: 0;
    transition: opacity 1s ease;
    svg{
      width: 100px;
      height: 100px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-settings-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: $shadow_color5;  
    padding: 10px;
    .video-bar{
      display: block;
      width: 100%;
      padding-bottom: 8px;
      input{
        width: 100%;
      }
    }
    .ant-btn{
      svg{
        transition: all 0.3s ease-out;
        position: relative;
        top: -1px;
      }
    }
    button {
      margin-right: 5px;
      &:hover{
        svg{
          color: $white_color;
        }
      }
    }
    input[type="range"] {
      cursor: pointer;
      flex-grow: 1;
      accent-color: $primary-green-background;
      &:hover{
        accent-color: $secondary-green-background;
      }
    }
  }
}




.organization-changing{
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
}
.person-selectbox{
  min-width:150px;
  padding-right: 10px;
  margin-bottom: 24px;
}

.custom-lang-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.custom-lang-select .ant-select-selector .ant-select-selection-item {
  color: $grey_color !important;
}

.custom-lang-select .ant-select-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.custom-lang-select .ant-select-item:hover {
  background-color: $grey3_color;
}

.custom-lang-select .flag {
  width: 24px;
  height: 16px;
  margin-right: 8px;
  object-fit: cover;
}
.custom-lang-dropdown{
  padding: 0 !important;
  border: 1px solid $grey2_color;
  border-radius:2px;
  z-index: 99999999;
}
.custom-lang-select .ant-select-arrow {
  color: $grey_color;
}


// Culture Modal

.culture-select-modal{
  width: 540px !important;
  .ant-modal-content{
    .ant-modal-close{
      //color: white;
    }
    .ant-modal-body{
      border-radius: 8px;
      padding: 20px;
    }
  }
}
.culture-select-modal-header{
  display:flex;
  align-items: center;
  justify-content: space-between;
  h2{
    font-size: 24px;
    line-height: 28px;
    font-weight:600;
    color: $grey_color;
    margin: 0;
  }
}
.culture-select-modal-select{
  margin:24px 0px;
  flex-direction: column;
}
.culture-input-label-text{
  color: $grey_color;
  font-size: 14px;
  line-height: 18px;
  font-weight:700;
}
