@import './color.scss';
.tab-menu{
  h1.page-title{
    display: flex;
    position: relative;
    top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: $secondary-dark-font-color; 
    height: 100%;
    line-height: 1;
    @media all and (max-width: 1275px) {
        padding-right: 20px;
    }
    .count-info{
      font-weight: 700;
      font-size:18px;
      padding-top: 5px;
      padding-left: 5px;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
    align-self: auto !important;  
    padding-right: 20px;
    @media all and (max-width: 680px) {
      display: none;
    }
  }
  @media all and (max-width: 680px) {
    .ant-tabs > .ant-tabs-nav{
      display: block;
    }
  }
  .tab-btn{
    button{
      @media all and (max-width: 482px) {
       span{
        font-size: 14px !important;
        padding-right: 12px;
       }
      }
      svg{
        position: relative;
        right: 6px;
        @media all and (max-width: 350px) {
          display: none !important;
        }
      }
      span {
        margin-left: 0px;
      }
    }
    .ant-btn {
      padding: 0;
    }
  }
  .ant-tabs-tabpane {
    min-height: 100px;
  }
  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs {
    line-height: 0;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color : $grey_color;
    svg{
      color: $blue_text_color;
    }
  }
  .ant-tabs-ink-bar {
    height: 5px;
    background: $blue_text_color !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color : $grey_color;
    text-shadow: none;
    span{
      font-weight: bold;
      font-size: $primary-font-size;
      color: $secondary-dark-font-color;
    }
    svg{
      color: $secondary-dark-font-color;
      width: 25px;   
      height: 19px;    
      position: relative;    
      top: 3px;    
      right: 1px;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 19px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
      color: $blue_text_color !important;
  }
  .ant-tabs-tab{
    svg{
      color: $secondary-dark-font-color;
      width: 25px;   
      height: 19px;    
      position: relative;    
      top: 3px;    
      right: 1px;
    }
    span{
      font-weight: bold;
      font-size: $primary-font-size;
      color: $secondary-dark-font-color;
    }
  }
  .ant-tabs-tab:hover{
    color : $blue_text_color !important;
  }
}
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: $secondary-green-font-color;
}
.ant-picker-input > input:hover , .ant-picker:hover, .ant-picker-focused {
  border-color: $primary-green-font-color;
}
.ant-picker-focused {
  box-shadow:$primary-green-box-shadow-color;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $primary-green-font-color;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background:  $primary-green-background;
}
.ant-btn-primary {
  border-color: $blue-border-color;
  background: $blue-border-color;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: $blue_hover_color;
  background: $blue_hover_color;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: $blue_color;
}
.ant-picker-today-btn {
  color: $primary-green-font-color;
}
.ant-picker-today-btn:hover {
  color: $secondary-green-font-color;
}
.add-user-draw-footer{
  .transparent-btn{
    font-weight: bold;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: $blue_color;
}
