@import './color.scss';

.report-container{
    .ant-select-selector{
        //max-width: 250px;
        max-height:80px;
        overflow-y: auto;
    }
    .ant-table-filter-dropdown{
        min-height: 300px !important;
    }
    // .ant-select-dropdown{
    //     max-height: 200px;
    // }
    // .ant-select-clear{
    //     right: 20px;
    // }
    .ant-form{
        padding: 0;
    }
    .ant-picker-range-separator {
        align-items: center;
        padding: 0 8px;
        line-height: 1;
        color:$grey6_color;
    }
    .page-title{
        padding-bottom: 30px;
    }
    ul.ant-pagination li:first-child{
        order: 10;
    }
    .ant-pagination-total-text{
        margin-right: 0;
        display: flex;
        justify-content: flex-end;
        padding-left: 15px;
    }
    .ant-pagination-total-text{
        color: $grey_color;
    }
    .filter-container{
        display: flex;
        width: 100%;
        border: 1px solid $grey2_color;
        background-color: $white_color;
        height: 100%;
        .filter-loading{
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 100%;
            height: 220px;
        }
        .ant-form{
            display: flex;
            flex-wrap: wrap;
            width:100%;
            .ant-form-item{
                margin-right: 20px;
                min-width: 18.5em;
            }
            .ant-picker{
                height: 40px;
                padding: 0;
            }
            .ant-picker-input > input{
                font-size: 14px;
            }
            .ant-picker-range .ant-picker-active-bar{
                background: $blue_text_color;
            }
            .filter-details .ant-picker-suffix{
                padding-right: 10px;
            }
            .filter-details .ant-picker-range .ant-picker-clear {
                right: 2px;
            }
            .filter-details .ant-picker-input > input{
                min-width: 16.5em;
            }
          
            @media screen  and (min-width:1460px) and (max-width:1570px) {
                .report-show-hide-button{
                    padding-top: 10px;
                    padding-bottom: 34px;
                    padding-left: 0;
                    width: 100%;
                }
            }
           
              @media screen  and (min-width:883px) and (max-width:1181px) {
                .filter-details .ant-picker-input > input{
                    min-width: 100% !important;
                    width: 100% !important;
                }
                .ant-form-item{
                    margin-right: 20px;
                    min-width: 24em;
                }
                .report-show-hide-button{
                    padding-top: 10px;
                    padding-bottom: 34px;
                    padding-left: 0;
                    width: 100%;
                }
                
            }
            @media screen  and (min-width:992px) and (max-width:1055px) {
                .filter-details .ant-picker-input > input{
                    min-width: 100% !important;
                    width: 100% !important;
                }
                .ant-form-item{
                    margin-right: 20px;
                    min-width: 22em !important;
                }
                .report-show-hide-button{
                    padding-top: 10px;
                    padding-bottom: 34px;
                    padding-left: 0;
                    width: 100%;
                }
                
            }
            @media screen and (max-width:883px) {
                .filter-details .ant-picker-input > input{
                    min-width: 100% !important;
                    width: 100% !important;
                }
                .ant-form-item{
                    margin-right: 0px;
                    min-width: 100%;
                    display:block !important;
                }
                .report-show-hide-button{
                    padding-top: 10px;
                    padding-bottom: 34px;
                    padding-left: 0;
                    width: 100%;
                }
            }
        
        }
        .filter-showable{
            display: flex;
            flex-wrap: wrap;
        }
        .filter-details{
            display: flex;
            flex-wrap: wrap;
        }
        .open-collapse{
            transition: transform 0.10s ease-in-out;
            transform-origin:top;
            height: auto;
        }
        .hide-collapse{ 
            transform: scaleY(0);
           // transform-origin: top;
           // transition: transform 0.10s ease-in-out;
           // height:0;
           max-height: 0;
           transition: max-height 0.15s ease-out;
           overflow: hidden;
        }
        .report-show-hide-button{
            display: flex;
            color: $blue_text_color;
            font-size: 16px;
            text-align: center;
            align-items: center;
            height: 40px;
        }
        .filter-button{
            display: flex;
            padding-bottom: 22px;
            width: 100%;
        }
    }
    
    .person-report-pai-chart-container{
        display: flex;
        justify-content: space-between;
        width:100%;
        padding:30px 0px;
        .person-report-pai-chart-items{
            justify-content: center;
            text-align: center;
            h2{
                color: $grey_color;
            }
        }
    }
}

