@import "./color.scss";
 .document-container, .ownership-document-container{
     width: 100%;
     display: flex;
     flex-wrap: wrap;

     .unicButton {  
        width: 180px;
        height: 34px;
        border-width: 1px;
    }

    .ant-tooltip-inner {
        color: #fff;
    }

    .editToolTip{
        border: 0;
    }
     .create-document-request-button{
         cursor: pointer;
         span{
             font-size: 16px;
             font-weight: 700;
             line-height: 28px;
        }
    }
     .document-name{
         font-size: 16px;
         color: $grey_color;
         line-height: 32px;
    }
     .error-container{
         width: 100%;
         max-width: 665px;
         display: flex;
         flex-wrap: wrap;
         padding-top: 10px;
         .error-containter-title{
             color: $grey_color;
             display: block;
             width: 100%;
             font-size: 16px;
             font-weight: 700;
             line-height: 20px;
             padding-bottom: 7px;
        }
         .error-container-table-title{
             padding-left: 5px;
             width: 100%;
             max-width: 220px;
        }
         .error-container-text{
             display: flex;
             padding-bottom: 17px;
             span{
                 font-size: 16px;
                 color: $grey_color;
                 line-height: 19px;
                 padding-left: 5px;
                 padding-right: 5px;
            }
        }
    }
     .ant-upload-list-picture{
         width: 100%;
    }
     .ant-upload-list-picture .ant-upload-list-item-card-actions {
         display: none;
    }
     .ant-upload-list-item-name{
         position: relative;
         top: -9px;
         color: $blue2_color;
    }
     .ant-upload-list-picture .ant-upload-list-item{
         border-radius: 5px !important;
         border: 2px solid $grey2_color;
         height: 62px;
         padding-left: 4px;
         padding-right: 37px;
         margin-bottom: 15px;
         svg{
             font-size: 23px;
        }
    }
     .ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
         width: auto;
         height: auto;
         margin-left: 14px;
         svg{
             position: relative;
             top: 2px;
        }
    }
     .ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
         width: 35px;
         height: 35px;
    }
     .ant-upload-list-item-error{
         border-color: $red-border-color !important;
         .ant-upload-list-item-card-actions {
             display: block !important;
        }
         .error-message{
             color: $red_color;
        }
         .ant-upload-list-item-card-actions-btn{
             width: 80px;
             height: 100%;
             margin-right: 8px;
             background-color: transparent;
             justify-content: center;
             padding-bottom: 10px;
             padding-top: 7px;
             svg{
                 width: 16px;
                 height: 16px;
                 position: relative;
                 top: 2px;
            }
             span{
                 padding-right: 10px;
                 font-size: 14px;
            }
        }
         .ant-upload-list-item-name{
             top: 0px;
             color: $red_color !important;
        }
         svg{
             path{
                 fill: $red2_color;
            }
        }
    }
     .ant-tooltip-content{
         display: none;
    }
     .ant-upload-list-item-progress{
         bottom: 12px;
         padding-right: 24px;
    }
     .ant-select{
         margin-right: 11px;
         width: 180px;
         margin-bottom: 10px;
    }
     .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
         height: 34px !important;
    }
     .document-upload-card-container{
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         .ant-card-bordered{
             border: 2px solid $grey2_color !important;
             border-radius: 5px;
        }
         .ant-card-body{
             padding: 15px;
        }
         .document-upload-card{
             width: 100%;
             svg{
                 font-size: 24px;
            }
             span{
                 display: flex;
                 align-items: center;
                 height: 100%;
                 font-size: 14px;
                 line-height: 17px;
                 color: $blue2_color;
            }
             .document-text{
                 padding-left: 10px;
            }
        }
         .document-action-buttons{
             display: flex;
             width: 100%;
             height: 100%;
             align-items: center;
             min-height: 70px;
        }
        .document-action-buttons-right{
            display: flex;
            width: 50px;
            margin-left: auto;
            height: 100%;
            align-items: center;
            min-height: 70px;
       }
    }
}

.empty-state-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
}

.empty-state-text {
    font-size: 16px;
    color: #666;
    text-align: center;
}


 