@import './color.scss';


.table-detail-menu-short{

    width: 150px;
    height: 100px;
    padding-top: 22px;
    a{
      font-size: 14px;
      color: $blue_text_color;
      line-height: 17px;
    }
    svg{
      font-size: 15px;
    }
    .ant-btn.transparent-btn{
      height: auto;
      padding: 0;
    }
    
}
.organization-container{
  .organization-space-between{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;
    .page-title{
      top: 0 !important;
    }
  }
  .organization-space-between {
      @media all and (max-width: 523px) {
        display: block;
        padding-top: 25px;
        .white-btn{
          min-width: 100%;
        }
        .primary-btn{
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0 !important;
        }
      }
  }
}

.organization-drawer{
  display: flex;
   .user-details h1{
      color: $grey_color;
      padding-bottom: 5px;
      line-height: 31px;
    }
   .ant-image{
    border:1px solid $grey2_color;
    border-radius: 50%;
    margin: auto;
    height: 121px;
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    text-align: center;
    justify-content: center;
   }
   .avatar {
    border-radius: 50%;
    padding: 3px;
    .ant-image-img {
        width: 100%;
        height: auto;
    }
  }
   .info-alt{
     color: $grey_color;
     h3{
       font-size: 16px !important;
     }
   }
   .procces{
     padding-top: 40px;
   }
   .change-avatar{
     span{
      color: $blue_text_color;
     }
     svg{
        position: relative;
        top: 3px;
     }
   }
   .change-avatar{
     cursor: pointer;
     width: 100%;
     display: flex;
     padding-top: 5px;
   }
}
.organization-logo{
  display: flex;
  text-align: center;
  align-items: center;
  height: 121px;
  width: 121px;
  padding:3px;
  border-radius: 50%;
}

.add-global-draw-title{
  display: flex;
  width: 100%;
  justify-content: center;
  h1{
    font-size: 24px;
    font-weight: 700;
    color:$grey_color;
  }
}
.add-global-close-icon{
  width: 100%;
  text-align: end;
  cursor: pointer;
  height: 22px;
  svg{
    font-size: 20px;
    color: $grey_color;
  }
}

