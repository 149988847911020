@import './color.scss';

.expense-drawer{
    .ant-radio-wrapper{
        padding-right: 40px;
    }
    .add-user-draw-footer {
        right: 0;
    }
    .ant-spin-nested-loading > div > .ant-spin{
        background: $black_color;
        opacity: 0.4;
    }
    .ant-spin-dot-item{
        background-color: $white_color;
    }
    .ant-spin-text{
        color: $white_color;
        text-shadow: none;
    }
    .blue-table .table-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
     padding-left: 13px; 
    }
    .table-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
        border-right: 2px solid $popup_border_color;
        padding-left: 13px !important;
    }
    .upload-icon{
        a{
            color: $blue_text_color;
        }
        svg{
            cursor: pointer;
            position: relative;
            color: $blue_text_color;
        }
    }
    .row {
        display: inline-flex;
        width: 100%;
        padding: 10px 20px 10px 20px;
        border: 1px solid $grey2_color !important;
        background-color: $blue_color;
        text-align: left;
        label{
            font-weight: 600 !important;
            font-size: 13px;
            padding-left: 3px;
        }
    }
    .editable {
        padding: 20px;
        border: 1px solid $grey2_color;
        border-top: 0px;
        display: flex;

        select {
          width:100px;
          background:transparent;
          appearance: none;
          background:#fff;
          border: 1px solid $grey2_color;
          appearance: auto;
        }
        .ant-input{
            height: 32.2px;
            top: -1px;
            font-size: 14px;
        }
        .ant-picker{
            padding: 6px 0px 5px;
            top: -1px;
        }
        .ant-picker-input{
            height: 19px;
        }
        .ant-picker-input > input{
            font-size: 14px;
        }
        .ant-picker-clear{
            margin-right: 4px;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            font-size: 14px;
        }
        button.ant-btn {
            top: -1px;
        }
    }
    .close-advance-checkbox-amount-data{
        padding-left: 25px;
        color: $grey_color;
        padding-bottom: 5px;
    }
    .m-r-20{
        margin-right: 10px;
    }
    .m-r-10{
        margin-right: 10px;
    }
    .advance-refunded-add-button{
        text-decoration: none;
        font-size: 16px;
        color: $blue_text_color;
        padding-right: 5px;
        padding-top: 3px;
    }
    .expense-drawer-advance-return-card-table{
        .advance-information, .received, .advanceDebt{
            max-width: 90px !important;
            width: 90px !important;
        }
        .return{
            max-width: 80px !important;
            width: 80px !important;
        }
        .exchange{
            max-width: 80px !important;
            width: 80px !important;
        }
        .delete{
            max-width: 60px !important;
            width: 60px !important;
            font-size: 14px;
            font-weight: 600;
            color: $blue_text_color;
            cursor: pointer;
        }
    }
    .expense-total-container{
        display: block;
        width: 100%;
        text-align: end;
        .expense-total-text{
            display: block;
            width: 100%;
            line-height: 1.5em;

        }
    }
    .expense-drawer-credits-card-table{
        .spendingAmount{
            max-width: 80px !important;
            width: 80px !important;
        }
        .amount{
            max-width: 90px !important;
            width: 90px !important;
        }
        .vatAmount{
            max-width: 80px !important;
            width: 80px !important;
        }
        .invoiceNo, .expenditureGroup, .currency {
            max-width: 100px !important;
            width: 100px !important;
        }
        .expenseDate{
            max-width: 120px !important;
            width: 120px !important;
        }
        .description, .receiptInfo{
            max-width: 80px !important;
            width: 80px !important;
        }
        .upload-icon {
            svg{
                top: 4px;
            }
        }
        .receipt-without-file{
            width: 64px;
        }
        .receipt-without-file-full{
            width: 95px;
            margin-right: 5px;
        }
        .receipt{
            width: 33px;
        }
        .receipt-full{
            width: 64px;
            margin-right: 5px;
        }
        .action-buttons{
            color: $blue_text_color;
            cursor: pointer;
            button{
                margin: 0px 2px;
            }
            svg{
                position: relative;
                top: 4px;
            }
        }
    }
    .expense-drawer-expense-card-table{
        .upload-icon {
            svg{
                top: 4px;
            }
        }
        .receipt-without-file{
            width: 64px;
        }
        .receipt-without-file-full{
            width: 95px;
            margin-right: 5px;
        }
        .receipt{
            width: 33px;
        }
        .receipt-full{
            width: 64px;
            margin-right: 5px;
        }
        .action-buttons{
            color: $blue_text_color;
            cursor: pointer;
            button{
                margin: 0px 2px;
            }
            svg{
                position: relative;
                top: 4px;
            }
        }
        .spendingAmount{
            width: 95px;
            max-width: 95px;
        }
      
        .currency,.expenditureGroup{
            width: 120px;
            max-width: 120px;
        }
     
        .expenseDate ,.amount, .invoiceNo, .receiptInfo,.description {
            width: 125px;
            max-width: 125px;
        }
     
    }
    .expense-drawer-currency-conversion-table{
      
        .expenseDate {
            width: 120px;
            max-width: 120px;
        }
        .cashedAmount, .convertedAmount,.cashedCurrency, .convertedCurrency, .tlAmount,.invoiceNo, .receiptInfo{
            width: 120px;
            max-width: 120px;
        }
        .receipt2{
            width: 100px;
            max-width: 100px;
        }
        .upload-icon {
            svg{
                top: 4px;
            }
        }
        .receipt-without-file{
            width: 64px;
        }
        .receipt-without-file-full{
            width: 95px;
            margin-right: 5px;
        }
        .receipt{
            width: 33px;
        }
        .receipt-full{
            width: 64px;
            margin-right: 5px;
        }
        .action-buttons{
            color: $blue_text_color;
            cursor: pointer;
            button{
                margin: 0px 2px;
            }
            svg{
                position: relative;
                top: 4px;
            }
        }
    }
    .ant-checkbox + span{
        font-size: 14px;
    }
    .ant-checkbox-wrapper{
        margin-bottom: 10px;
    }
    .ant-select-item-option-content {
        text-align: left !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
        text-align: left !important;
    }
    .ant-checkbox-group {
        width: 100%;
    }
    .file-width{
        display: inline-flex;
        //width: 30px;
    }
}