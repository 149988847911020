@import './color.scss';
@import "./break-points.scss";

$left_menu_color : $secondary-light-font-color;
$left_menu_active_color : $white_color;
$left_menu_active_hover_color : $white_color;
$left_menu_hover_color: $menu_hover_color;
$left_menu_background_color: $primary-dark-background;
$left_menu_active_background_color : transparent;

.ant-layout-sider-collapsed{
    flex: 0 0 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
    width: 70px !important;
}

.ant-layout-sider {
    background-color: $left_menu_background_color;
    transition: all 0s !important;
    top:0 !important;
    padding-top: 68px;
    &.sider-with-bar{
        padding-top: 128px;
    }
    @media all and (max-width:$custom-breakpoint-xl){
        &.sider-with-bar{
            padding-top: 168px;
        }
    }
}

.left-menu{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-left:5px;
    .ant-menu{
        font-size:$primary-font-size;
    }
    .ant-menu-item-icon{
        width: 0.95rem;
        height: 1.25rem;
    }

    .ant-menu-inline-collapsed{
        svg{
            width: 1.2rem;
            height: 1.2rem;
        }
        .ant-menu-item,.ant-menu-submenu-title{
            padding-left:26px !important;
            margin-top:8px;
        }
        // .ant-menu-item:hover,
        // .ant-menu-item-active,
        // .ant-menu-submenu-active,
        // .ant-menu-submenu-open,
        // .ant-menu-submenu-selected,
        .ant-menu-submenu-title:hover {
            color: $left_menu_active_hover_color !important;
            background-color: transparent;
        }
    }

    .transparent-btn {
        color: $left_menu_color;
        height: 25px;
        padding-top: 15px;
    }

    .transparent-btn:hover, .transparent-btn:focus, .transparent-btn::selection {
        color: $left_menu_hover_color;
    }

    .ant-menu-item,.ant-menu-submenu-title{
        padding-left:15px !important;
        padding-right:20px !important;
    }

    .ant-menu-submenu .ant-menu-item{
        padding-left:49px !important;
    }

    .ant-menu-item .ant-menu-item-icon + span,
    .ant-menu-submenu-title .ant-menu-item-icon + span,
    .ant-menu-item .anticon + span,
    .ant-menu-submenu-title .anticon + span{
        margin-left:13px;
    }
    .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content{
        line-height: 20px;
        white-space: pre-line;
    }
    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a,
    .ant-menu-dark .ant-menu-item > span > a,
    .ant-menu-dark .ant-menu-submenu-open{
        color: $left_menu_color;
    }

    .ant-menu-dark .ant-menu-item.ant-menu-item-selected,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item.ant-menu-item-selected > a,
    .ant-menu-dark .ant-menu-item.ant-menu-item-selected > span > a {
        color: $left_menu_active_color;
    }
    
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-title:hover,
    .ant-menu-dark .ant-menu-submenu-active.ant-menu-submenu-open.ant-menu-submenu-selected>div.ant-menu-submenu-title:hover{
        color: $left_menu_hover_color;
        background-color: transparent;
    }

    .ant-menu-dark .ant-menu-submenu-active.ant-menu-submenu-selected>div{
        color:$left_menu_active_hover_color;
    }

    .ant-menu-dark .ant-menu-item:hover > a,
    .ant-menu-dark .ant-menu-item-active > a,
    .ant-menu-dark .ant-menu-submenu-active > a,
    .ant-menu-dark .ant-menu-submenu-open > a,
    .ant-menu-dark .ant-menu-submenu-selected > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > a,
    .ant-menu-dark .ant-menu-item:hover > span > a,
    .ant-menu-dark .ant-menu-item-active > span > a,
    .ant-menu-dark .ant-menu-submenu-active > span > a,
    .ant-menu-dark .ant-menu-submenu-open > span > a,
    .ant-menu-dark .ant-menu-submenu-selected > span > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
        color: $left_menu_hover_color;
    }

    .ant-menu-dark .ant-menu-item-active.ant-menu-item-selected > span > a{
        color: $left_menu_active_hover_color !important;
    }

    .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
        color: $left_menu_color;
        background: $left_menu_background_color;
    }

    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
        opacity: 1;
    }

    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
        opacity: 0;
    }

    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after  {
        background: $left_menu_color !important;
    }

    .ant-menu-dark .ant-menu-submenu-selected.ant-menu-submenu-open.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected.ant-menu-submenu-open.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after{
        background: $left_menu_hover_color!important;
    }

    .ant-menu-dark .ant-menu-submenu-selected.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after{
        background: $left_menu_active_hover_color!important;
    }

    .ant-menu-dark .ant-menu-submenu-selected.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after{
        background: $left_menu_color!important;
    }

    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after{
        background: $left_menu_active_color!important;
    }

    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after{
        background: $left_menu_hover_color!important;
    }
}
.left-menu-info {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size:12px;
    color: $left_menu_color;

    a {
        color: $left_menu_color;
    }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $left_menu_active_background_color;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: $left_menu_active_hover_color;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
    color: $left_menu_color;
}

.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
.ant-menu-dark .ant-menu-item.ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item.ant-menu-item-selected > span > a {
    color: $left_menu_active_color;
}

.ant-menu-dark .ant-menu-item.ant-menu-item-active,
.ant-menu-dark .ant-menu-item.ant-menu-item-active > a,
.ant-menu-dark .ant-menu-item.ant-menu-item-active > span > a {
    color: $left_menu_hover_color;
}

.ant-menu-dark .ant-menu-item.ant-menu-item-active.ant-menu-item-selected,
.ant-menu-dark .ant-menu-item.ant-menu-item-active.ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item.ant-menu-item-active.ant-menu-item-selected > span > a {
    color: $left_menu_active_hover_color;
}

.collapse-btn-pad.center{
    button{
        padding: 0 calc(50% - 16px / 2);
        padding-top:15px;
    }
}

.collapse-btn-pad.right{
    text-align: right;
}

.ant-tooltip-placement-right{
    left:60px !important;
    position:fixed;
    width: 170px;
    // top:114px !important;
    // display: unset !important;
}

.ant-tooltip-inner {
    color: $left_menu_color;
    background-color: $left_menu_background_color;
    box-shadow: none;
    a{
        color: $left_menu_color;
        padding-left: 10px;
    }
    a:hover{
        color: $left_menu_hover_color;
    }
}

.ant-tooltip-arrow-content {
    background-color: $left_menu_background_color;
}

.ant-menu-submenu-placement-rightTop {
    left:70px !important;
    position:fixed;
    border-radius: 0 2px 2px 0;
    // top:259px !important;
    // display: unset !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    color: $left_menu_color;
    background: $left_menu_background_color;
    box-shadow: none;
}
