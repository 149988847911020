// Onboarding

@import './color.scss';

.logo-url{
    min-width:240px;
  }
  .logo-text{
    margin-left:15px;
  }
  .lh-normal{
    line-height: normal;
  }
  .rocket-border{
    max-width: 60px;
    border: 3px solid #E4E4E4;
    border-radius: 50%;
  }
  
  .progress-border {
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    border: 0px solid #ddd;
    background-color: white;
  }
  .rocket-img{
    width:64px;
    height:64px;
  }
  
  .progress-border::before {
    content: '';
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    background-color: transparent;
    background-image: conic-gradient(#E4E4E4, #E4E4E4 100%, transparent 100%);
    z-index: -101;
    border-radius: 50%;
  }
  .progress-border::after {
    content: '';
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    background-color: transparent;
    background-image: conic-gradient(
      $water-green-500, 
      $water-green-500 var(--progress-percentage, 15%), 
      transparent var(--progress-percentage, 15%)
    );
    z-index: -100;
    border-radius: 50%;
  }
  
  .progress-border-popover::before {
    background-image: conic-gradient(
      #C4C4C4, 
      #C4C4C4 var(--progress-percentage, 100%), 
      transparent var(--progress-percentage, 100%)
    );
  }
  
  .progress-border-popover::after {
    background-image: conic-gradient(
      $water-green-500, 
      $water-green-500 var(--progress-percentage, 15%), 
      transparent var(--progress-percentage, 15%)
    );
  }
  
  
  .onboarding-welcome-modal{
    display:flex;
    flex-direction:column;
  }
  .onboarding-welcome-main{
    display:flex;
    align-items:center;
    gap: 16px;
    padding: 18px 0px;
    flex-direction:row;
    &.flex-reverse{
      flex-direction:row-reverse;
    }
  }
  .rocket-modal-image{
    margin: auto 0px;
  }
  
  .rocket-box-wrapper{
    max-width:80%;
    padding:4px;
  }
  .rocket-box-no-arrow {
    border:1px solid #C4C4C4;
    width:100%;
    padding:12px;
    border-radius: 8px;
  }
  .rocket-box-no-arrow-img {
    min-width:90px;
    min-height: 90px;
  }
  .rocket-box {
      position: relative;
      max-width: 30em;
      background-color: white;
      padding: 12px 16px;
      border-radius: 1rem;
    box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
  }
  
  .rocket-box::before {
      // layout
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 50%;
      right: 98%; // offset should move with padding of parent
      border: 0.75rem solid transparent;
      border-top: none;
      rotate:-90deg;
      // looks
      border-bottom-color: white;
      filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .2));
  }
  
  .onboard-popover-content{
    position:relative;
  }
  .onboard-popover-close{
    position:absolute;
    right:10px;
    top:16px;
    cursor:pointer;
    &.left{
      right:20px;
    }
  }
  .onboard-popover-title{
    color : $primary-dark-font-color;
    font-size:18px;
    font-weight:600;
    margin:0;
    .green{
      color : $primary-green-font-color;
    }
  }
  .onboard-popover-text{
    color : $primary-dark-font-color;
    font-size:16px;
  }
  
  .onboard-header-task-container{
    display:flex;
    flex-direction:column;
  }
  .onboard-popover-wrapper .ant-popover-content .ant-popover-inner,
  .onboard-popover-wrapper .ant-popover-content .ant-popover-arrow .ant-popover-arrow-content
  {
    background: #E4E4E4;
  }
  .onboard-popover-wrapper .ant-popover-content .ant-popover-inner .ant-popover-inner-content{
    padding: 0px;
  }
  .onboard-popover-modal-percentage{
    width:50px;
    height: 50px;
    background:white;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .onboard-popover-wrapper .ant-popover-content .ant-popover-inner{
    border-radius: 8px;
  }
  .onboard-nav-popover-wrapper .ant-popover-content .ant-popover-inner{
    border-radius: 8px;
  }
  .onboard-popover-task-wrapper{
    display:flex;
    flex-direction:column;
    padding:16px;
    gap:12px;
    background:white;
    border-radius:0 0 8px 8px;
  }
  .onboard-popover-task-single{
    display:flex;
    gap:12px;
    align-items: center;
    width:100%;
    padding:8px;
    border-radius:6px;
    border: 1px solid #E4E4E4;
    cursor:pointer;
    &:hover{
      border: 1px solid #65C2C9
    }
    &.active{
      border: 1px solid #65C2C9
    }
  }
  .onboard-task-icon{
    width:48px;
    height:48px;
    min-width:48px;
    min-height:48px;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:12px;
    color:#65C2C9;
    background:#F5FBFC;
  }
  .onboard-next-back-section{
    display:flex;
    align-items:center;
    justify-content: space-between;
    background:white;
  }
  .onboard-next-back{
    display:flex;
    gap:4px;
    color:$water-green-500;
    background:white;
    font-size:12px;
    align-items:center;
    user-select: none;
    cursor:pointer;
  }
  .onboarding-nav-item{
    outline: #FFB134 solid 2px !important;
    margin: auto;
    max-width: 90%;
    border-radius: 4px;
    z-index: 1001;
    
  }
  .onboarding-in-list{
    background: white;
    padding: 2px 4px;
  }
  .onboarding-button-border{
    border: #FFB134 solid 2px !important;
    border-radius: 4px;
    z-index: 1001;
    &.bg-white{
      background:white;
      &:hover{
        background:white;
      }
    }
  }
  .onboarding-drawer-border{
    max-width: 300px;
    border: #FFB134 solid 2px !important;
    z-index: 1002;
    padding: 8px;
    border-radius: 8px;
    position: relative;
  }
  .onboarding-drawer-tab-border{
    max-width: 300px;
    border: #FFB134 solid 2px !important;
    z-index: 1002;
    padding: 2px 4px;
    border-radius: 8px;
    position: relative;
  }
  .onboarding-first-mission-wrapper{
    display:flex;
    flex-direction:column;
    gap:20px;
    align-items:center;
    justify-content: center;
    .mission-title{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #363740;
    }
    .mission-description{
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #595959;
    }
  }
  
  .onboarding-map-modal{
    width: 826px !important;
    .ant-modal-content{
      .ant-modal-close{
        color: white;
      }
      background: transparent;
      .ant-modal-body{
        background-image: url(../assets/images/hrplan-onboarding-image.png);
        height: 422px;
        padding: 0;
      }
    }
  }
  .onboarding-map-modal-wrapper{
    position:relative;
    width: 826px;
    height: 422px;
  }
  @mixin circle($size, $top, $left) {
    position: absolute;
    top: $top;
    left: $left;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: white;
    border: 1px solid $water-green-500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }
  
  .onboarding-mission {
    &-start { @include circle(48px, 206px, 8px); }
    &-1 { @include circle(36px, 212px, 68px); }
    &-2 { @include circle(36px, 212px, 116px); }
    &-3 { @include circle(36px, 212px, 164px); }
    &-first { @include circle(48px, 206px, 212px); }
    &-4 { @include circle(36px, 212px, 272px); }
    &-5 { @include circle(36px, 212px, 320px); }
    &-6 { @include circle(36px, 212px, 368px); }
    &-second { @include circle(48px, 206px, 416px); }
    &-7 { @include circle(36px, 212px, 476px); }
    &-8 { @include circle(36px, 212px, 524px); }
    &-9 { @include circle(36px, 212px, 572px); }
    &-third { @include circle(48px, 206px, 620px); }
    &-10 { @include circle(36px, 212px, 680px); }
    &-11 { @include circle(36px, 212px, 728px); }
    &-end { @include circle(48px, 206px, 773px); }
  }
  
  .onboarding-done-number{
    font-size:16px;
    font-weight:600;
    color:$water-green-500;
  }
  .onboard-check{
    background-color:#70C7C3;
  }

  .onboarding-next-mission{
    position:absolute;
    right:12px;
    bottom:12px;
    background: white;
  }
  .onboarding-modal-bubble {
    position:absolute;
    left:12px;
    top:12px;
    width: 363px;
    padding: 16px 20px;
    gap: 12px;
    display:flex;
    flex-direction:column;
    background:white;
    border-radius:8px;
  }
  .onboarding-popover-hidden{
    display: none;
  }