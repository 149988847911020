@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-VariableFont_wght.ttf');   
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
$font-family: 'Roboto', sans-serif;

$primary-font-size: 0.95rem;
$secondary-font-size: 0.95rem;
$primary-line-height: 1.425rem;