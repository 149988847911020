@import './color.scss';

.table-detail-menu{
    max-width: 208px;
    padding-top: 22px;
    a{
      font-size: 14px;
      color: $blue_text_color;
      line-height: 17px;
    }
    svg{
      font-size: 15px;
    }
    .ant-btn.transparent-btn{
      height: auto;
      padding: 0;
    }
  }
  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
}
.organization-detail-dropdown{
  width: 178px;
  button{
    padding-left: 0;

  }
}

.other-proccess-menu{
  width: 178px;
  button{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    span{
      position: relative;
      top: 1.5px;
      left: 1px;
    }
  }
  a{
    font-size: 14px;
    color: $blue_text_color;
    line-height: 17px;
  }
  svg{
    position: relative;    
    top: 1px;
    font-size: 15px;
    margin-right: 4px;
    width: 20px;
    height: 20px;

  }
  .ant-btn.transparent-btn{
    height: auto;
    padding: 0;
    margin-bottom: 2px;

  }
}

.table-list-dropdown{
  width: 100%;
  min-width: 180px;
  padding-top: 12px;
  padding-bottom: 10px;
  &.popover{
    transform: translateY(-20px);
    outline: #FFB134 solid 2px !important;
    margin: auto;
    max-width: 100%;
    border-radius: 4px;
    z-index: 1001;
  }
  a{
    font-size: 14px;
    color: $blue_text_color;
    line-height: 17px;
  }
  svg{
    position: relative;    
    top: 3px;
    margin-right: 4px;
    width: 17px;
    height: 17px;
  }
  .ant-btn.transparent-btn{
    height: auto;
    padding: 0;
  }
}

  .debit-menu{
    min-width: 190px;
    height: 143px;
    padding-top: 22px;
    svg{
      font-size: 15px;
    }
    .ant-btn.transparent-btn{
      height: auto;
      padding: 0;
    }
  }
  .permission-menu{
    min-width: 165px;
    // height: 200px;
    padding-top: 22px;
    padding-left: 14px;
    padding-right: 14px;
    svg{
      position: relative;
      top: 3px;
      margin-right: 4px;
      width: 17px;
      height: 17px;
    }
    .ant-btn.transparent-btn{
      height: auto;
      padding: 0;
    }
  }

  .advance-request-dropdown{
    min-width: 180px;
    height: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 14px;
    padding-right: 14px;
    svg{
      position: relative;
      top: 4px;
      font-size: 15px;
    }
    .ant-btn.transparent-btn{
      height: auto;
      padding: 0;
    }
    .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
      background-color: transparent !important;
  }
  }
  .advance-more-toggle{
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    height: 100%;
    color: $blue_text_color;
    z-index: 10;
    padding-right: 10px;
    padding-top: 8px;
  }
  .settings-dropdown{
    font-size: 14px;
    padding-top: 17px;
    padding-bottom: 17px;

    svg{
      position: relative;
      width: 17px;
      height: 17px;
      color: $blue_text_color;
      top: 3px;
      margin-right: 7px;
    }
    .settings-title{
      
      h3{
        padding-top: 21px;
        padding-left: 17px;
        padding-bottom: 14px;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
      }
     }
    }

    .dropdown-popover{
      outline: #FFB134 solid 2px !important;
      margin: auto;
      max-width: 100%;
      border-radius: 4px;
      z-index: 1001;
    }
    .translate-y-20{
      transform: translateY(-20px);
    }

  

